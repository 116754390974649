import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';


function Contact(props) {
    const [contact, setcontact] = useState({
        name:"",
        email:"",
        subject:"",
        message:""
    })
    const [issuccess, setsuccess] = useState("")
    const validator = new SimpleReactValidator({messages: {
        default: 'This field is required'
      },});

    const [error, setError] = useState({});



    const handleInputs = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setcontact({...contact, [name]:value})
    }

    const submitdata = async(e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setError({});	
        } else {
            setError(validator.getErrorMessages());		
            return;			
        }
        // console.log(contact);return
        const header = new Headers();
        header.append('Content-Type', 'application/json');

        const res = await fetch('https://webapi.didbiz.com/submitContact/',{
            method:'POST',
            headers:header,
            body: JSON.stringify({
                contact
            })
        })

        const data = await res.json();
        
        // console.log(data);return false;
        if(data.status === 201 || !data){
            setsuccess(1)
            setcontact({
                name:"",
                email:"",
                subject:"",
                message:""
            })
        }else{              
            setsuccess(0)
        }      
    }
    

    return (
        <div>
            
            <section className="wrapper bg-soft-primary">
            <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                <div className="row">
                <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
                    <h1 className="display-1 mb-3">Get in Touch</h1>
                    <nav className="d-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact</li>
                    </ol>
                    </nav>
                    
                </div>
                
                </div>
                
            </div>
            
            </section>
            
            <section className="wrapper bg-light angled upper-end">
            <div className="container py-14 py-md-16">
                <div className="row gy-10 gx-lg-8 gx-xl-12 mb-16 align-items-center">
                <div className="col-lg-7 position-relative">
                {/* " style="top: 0; left: -1.4rem; z-index: 0;" */}
                    <div className="shape bg-dot primary rellax w-18 h-18" data-rellax-speed="1"></div>
                    <div className="row gx-md-5 gy-5">
                    <div className="col-md-6">
                        <figure className="rounded mt-md-10 position-relative"><img src="assets/img/photos/g5.jpg" srcSet="./assets/img/photos/g5@2x.jpg 2x" alt=""/></figure>
                    </div>
                    
                    <div className="col-md-6">
                        <div className="row gx-md-5 gy-5">
                        <div className="col-md-12 order-md-2">
                            <figure className="rounded"><img src="assets/img/photos/g6.jpg" srcSet="./assets/img/photos/g6@2x.jpg 2x" alt=""/></figure>
                        </div>
                        
                        <div className="col-md-10">
                            <div className="card bg-pale-primary text-center counter-wrapper">
                            <div className="card-body py-11">
                                <h3 className="counter text-nowrap">5000+</h3>
                                <p className="mb-0">Satisfied Customers</p>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                <div className="col-lg-5">
                    <h2 className="display-4 mb-8">Convinced yet? Let's make something great together.</h2>
                    <div className="d-flex flex-row">
                    <div>
                        <div className="icon text-primary fs-28 me-6 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
                    </div>
                    <div>
                        <h5 className="mb-1">Address</h5>
                        <address>1598 Gould St. <br className="d-none d-md-block" />Dallas, Texas</address>
                    </div>
                    </div>
                    <div className="d-flex flex-row">
                    <div>
                        <div className="icon text-primary fs-28 me-6 mt-n1"> <i className="uil uil-phone-volume"></i> </div>
                    </div>
                    <div>
                        <h5 className="mb-1">Phone</h5>
                        <p><a href="tel:+18445339911">(844) 533-9911</a></p>
                    </div>
                    </div>
                    <div className="d-flex flex-row">
                    <div>
                        <div className="icon text-primary fs-28 me-6 mt-n1"> <i className="uil uil-envelope"></i> </div>
                    </div>
                    <div>
                        <h5 className="mb-1">E-mail</h5>
                        <p className="mb-0"><a href="mailto:info@didbiz.com" className="link-body">info@didbiz.com</a></p>
                    </div>
                    </div>
                </div>
                
                </div>
                
                <div className="row">
                <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                    <h3 className="display-4 mb-3 text-center">Drop Us a Line</h3>
                    <p className="lead text-center mb-10">Reach out to us from our contact form and we will get back to you shortly.</p>
                    <form className="contact-form needs-validation" method="post" action="https://sandbox.elemisthemes.com/assets/php/contact.php">
                    <div className="messages"></div>
                    <div className="row gx-4">
                        <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input id="form_name" type="text" name="name" className="form-control" value={contact.name} onChange={handleInputs} placeholder="Jane" required/>
                            <label htmlFor="form_name">Full Name *</label>   
                            {validator.message('name', contact.name, 'required')}						
                             {error.name!==""? <span className="invalid-feedback">{error.name}</span>:null}
                         
                        </div>
                        </div>
                        
                        {/* <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input id="form_lastname" type="text" name="surname" className="form-control" value={contact.name} onChange={handleInputs} placeholder="Doe" required/>
                            <label htmlFor="form_lastname">Last Name *</label>
                            <div className="valid-feedback"> Looks good! </div>
                            <div className="invalid-feedback"> Please enter your last name. </div>
                        </div>
                        </div> */}
                        
                        <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input id="form_email" type="email" name="email" className="form-control" value={contact.email} onChange={handleInputs} placeholder="jane.doe@example.com" required/>
                            <label htmlFor="form_email">Email *</label>
                            {validator.message('email', contact.email, 'required')}						
                             {error.email!==""? <span className="invalid-feedback">{error.email}</span>:null}
                        </div>
                        </div>

                        <div className="col-md-12">
                        <div className="form-floating mb-4">
                            <input id="form_name" type="text" name="subject" className="form-control" value={contact.subject} onChange={handleInputs} placeholder="Your subject" required/>
                            <label htmlFor="form_name">Subject *</label>   
                            {validator.message('subject', contact.subject, 'required')}						
                             {error.subject!==""? <span className="invalid-feedback">{error.subject}</span>:null}                         
                        </div>
                        </div>
                        
                        {/* <div className="col-md-6">
                        <div className="form-select-wrapper">
                            <select className="form-select" id="form-select" required>
                            <option selected disabled value="">Select a department</option>
                            <option value="1">Sales</option>
                            <option value="2">Marketing</option>
                            <option value="3">Customer Support</option>
                            </select>
                            <div className="valid-feedback"> Looks good! </div>
                            <div className="invalid-feedback"> Please select a department. </div>
                        </div>
                        </div> */}
                        
                        <div className="col-12">
                        <div className="form-floating mb-4">
                            <textarea id="form_message" name="message" className="form-control" value={contact.message} onChange={handleInputs} placeholder="Your message" style={{'height':"150px"}} required></textarea>
                            <label htmlFor="form_message">Message *</label>
                            {validator.message('message', contact.message, 'required')}						
                             {error.message!==""? <span className="invalid-feedback">{error.message}</span>:null}              
                        </div>
                        </div>
                        
                        <div className="col-12 text-center">
                        <input onClick={(e) => submitdata(e)} type="submit" className="btn btn-primary rounded-pill btn-send mb-3" value="Send message"/>
                        {issuccess == 1 && 
                        <div className="valid-feedback"> Contact form Submitted Successfully </div>
                        }                        
                        </div>
                        
                    </div>
                    
                    </form>
                    
                </div>
                
                </div>
                
            </div>
            
            </section>
            
        </div>
    );
}

export default Contact;