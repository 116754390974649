import React from 'react';
import { Route, Link } from 'react-router-dom';
  
const Header = () => {
    return (
        <>
        {/* <Route> */}
            
                <header className="wrapper bg-soft-primary">
                    <nav className="navbar center-nav transparent navbar-expand-lg navbar-light">
                        <div className="container flex-lg-row flex-nowrap align-items-center">
                            <div className="navbar-brand w-100 display-3">
                                {/* <Link to="/"><img src="../../assets/img/logo-dark.png" srcset="../../assets/img/logo-dark@2x.png 2x" alt="" /></Link> */}
                                <Link className="dropdown-item" to="/">Didbiz</Link>
                            </div>
                            <div className="navbar-collapse offcanvas-nav">
                                <div className="offcanvas-header d-lg-none d-xl-none display-3">
                                    {/* <Link to="/"><img src="../../assets/img/logo-light.png" srcset="../../assets/img/logo-light@2x.png 2x" alt="" /></Link> */}
                                    Didbiz
                                    <button type="button" className="btn-close btn-close-white offcanvas-close offcanvas-nav-close" aria-label="Close"></button>
                                </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item"><Link className="nav-link" to={"/"}>Home</Link></li>
                                    <li className="nav-item dropdown"><a href="#" className="nav-link dropdown-toggle" >Services</a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><Link className="dropdown-item" to={'/did-numbers'}>Phone Numbers (DID)</Link></li>
                                            <li className="nav-item"><Link className="dropdown-item" to={'/messaging'}>Messaging</Link></li>
                                            <li className="nav-item"><Link className="dropdown-item" to={'/programming-voice'}>Programmable Voice</Link></li>
                                            <li className="nav-item"><Link className="dropdown-item" to={'/sip-trunking'}>SIP Trunking</Link></li>
                                            {/* <li className="nav-item"><a className="dropdown-item" href="projects4.html">Projects IV</a></li>
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Single Projects</a>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item"><a className="dropdown-item" href="single-project.html">Single Project I</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="single-project2.html">Single Project II</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="single-project3.html">Single Project III</a></li>
                                                </ul>
                                            </li> */}
                                        </ul>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/blog">Blog</Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/contact-us">Contact Us</Link></li>

                                    {/* <li className="nav-item"><a className="nav-link" href="#">Demos</a>
                                        <ul className="dropdown-menu mega-menu mega-menu-img">
                                            <li className="mega-menu-content">
                                                <ul className="row row-cols-1 row-cols-lg-6 gx-0 gx-lg-4 gy-lg-2 list-unstyled">
                                                    <li className="col"><a className="dropdown-item" href="index.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi1.jpg" srcset="../../assets/img/demos/mi1@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo I</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index2.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi2.jpg" srcset="../../assets/img/demos/mi2@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo II</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index3.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi3.jpg" srcset="../../assets/img/demos/mi3@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo III</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index4.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi4.jpg" srcset="../../assets/img/demos/mi4@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo IV</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index5.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi5.jpg" srcset="../../assets/img/demos/mi5@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo V</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index6.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi6.jpg" srcset="../../assets/img/demos/mi6@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo VI</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index7.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi7.jpg" srcset="../../assets/img/demos/mi7@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo VII</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index8.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi8.jpg" srcset="../../assets/img/demos/mi8@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo VIII</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index9.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi9.jpg" srcset="../../assets/img/demos/mi9@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo IX</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index10.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi10.jpg" srcset="../../assets/img/demos/mi10@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo X</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index11.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi11.jpg" srcset="../../assets/img/demos/mi11@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo XI</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index12.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi12.jpg" srcset="../../assets/img/demos/mi12@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo XII</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index13.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi13.jpg" srcset="../../assets/img/demos/mi13@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo XIII</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index14.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi14.jpg" srcset="../../assets/img/demos/mi14@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo XIV</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index15.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi15.jpg" srcset="../../assets/img/demos/mi15@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo XV</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index16.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi16.jpg" srcset="../../assets/img/demos/mi16@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo XVI</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index17.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi17.jpg" srcset="../../assets/img/demos/mi17@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo XVII</span>
                                                        </a></li>
                                                    <li className="col"><a className="dropdown-item" href="index18.html">
                                                            <figure className="rounded lift d-none d-lg-block"><img src="../../assets/img/demos/mi18.jpg" srcset="../../assets/img/demos/mi18@2x.jpg 2x" alt=""/></figure>
                                                            <span className="d-lg-none">Demo XVIII</span>
                                                        </a></li>
                                                </ul>
                                            </li>
                                        </ul>                                       
                                    </li> */}
                                   
                                    {/* <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#">Blocks</a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a className="dropdown-item" href="blocks.html">About</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks2.html">Blog</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks3.html">Clients</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks4.html">Contact</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks5.html">Facts</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks6.html">FAQ</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks7.html">Portfolio</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks8.html">Pricing</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks9.html">Process</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks10.html">Services</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks11.html">Team</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="blocks12.html">Testimonials</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#">Pages</a>
                                        <ul className="dropdown-menu">
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Services</a>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item"><a className="dropdown-item" href="services.html">Services I</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="services2.html">Services II</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">About</a>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item"><a className="dropdown-item" href="about.html">About I</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="about2.html">About II</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Blog</a>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item"><a className="dropdown-item" href="blog.html">Blog without Sidebar</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="blog2.html">Blog with Sidebar</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="blog3.html">Blog with Left Sidebar</a></li>
                                                    <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Blog Posts</a>
                                                        <ul className="dropdown-menu">
                                                            <li className="nav-item"><a className="dropdown-item" href="blog-post.html">Post without Sidebar</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="blog-post2.html">Post with Sidebar</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="blog-post3.html">Post with Left Sidebar</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Contact</a>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item"><a className="dropdown-item" href="contact.html">Contact I</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="contact2.html">Contact II</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="contact3.html">Contact III</a></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item"><a className="dropdown-item" href="pricing.html">Pricing</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#">Features</a>
                                        <ul className="dropdown-menu">
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Header</a>
                                                <ul className="dropdown-menu">
                                                    <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Center Nav</a>
                                                        <ul className="dropdown-menu">
                                                            <li className="nav-item"><a className="dropdown-item" href="header.html">Transparent</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header2.html">Light</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header3.html">Dark</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header4.html">Fancy</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Classic</a>
                                                        <ul className="dropdown-menu">
                                                            <li className="nav-item"><a className="dropdown-item" href="header5.html">Transparent</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header6.html">Light</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header7.html">Dark</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header8.html">Fancy</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Center Logo</a>
                                                        <ul className="dropdown-menu">
                                                            <li className="nav-item"><a className="dropdown-item" href="header9.html">Transparent</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header10.html">Light</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header11.html">Dark</a></li>
                                                            <li className="nav-item"><a className="dropdown-item" href="header12.html">Fancy</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="nav-item"><a className="dropdown-item" href="header13.html">Extended</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Footer</a>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item"><a className="dropdown-item" href="footer.html">Footer I</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="footer2.html">Footer II</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="footer3.html">Footer III</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="footer4.html">Footer IV</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="footer5.html">Footer V</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Page Title</a>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item"><a className="dropdown-item" href="page-title.html">Page Title I</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="page-title2.html">Page Title II</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="page-title3.html">Page Title III</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a className="dropdown-item dropdown-toggle" href="#">Modal Popup</a>
                                                <ul className="dropdown-menu">
                                                    <li className="nav-item"><a className="dropdown-item" href="modal-popup1.html">Modal Popup I</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="modal-popup2.html">Modal Popup II</a></li>
                                                    <li className="nav-item"><a className="dropdown-item" href="modal-popup3.html">Modal Popup III</a></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item"><a className="dropdown-item" href="notification-bar.html">Notification Bar</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="colors.html">Colors</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="onepage.html">One Page</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><a className="nav-link" href="elements.html">Elements</a>
                                        <ul className="dropdown-menu mega-menu">
                                            <li className="mega-menu-content">
                                                <div className="row gx-0 gx-lg-3">
                                                    <div className="col-lg-6">
                                                        <h6 className="dropdown-header">General Elements</h6>
                                                        <div className="row gx-0">
                                                            <div className="col-lg-6">
                                                                <ul className="list-unstyled">
                                                                    <li><a className="dropdown-item scroll" href="elements.html#buttons-badges">Buttons & Badges</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#counter">Counter</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#dividers">Dividers</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#form-elements">Form Elements</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#icon-box">Icon Box</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#image-hover">Image Hover</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#lightbox">Lightbox</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#modal">Modal</a></li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <ul className="list-unstyled">
                                                                    <li><a className="dropdown-item scroll" href="elements.html#pricing-table">Pricing Table</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#process">Process</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#progressbar">Progressbar</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#shadows">Shadows</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#tabs">Tabs</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#team">Team</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#testimonials">Testimonials</a></li>
                                                                    <li><a className="dropdown-item scroll" href="elements.html#toggle-accordion">Toggle & Accordion</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>                                                      
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <h6 className="dropdown-header">Misc Elements</h6>
                                                        <ul className="list-unstyled">
                                                            <li><a className="dropdown-item" href="carousel.html">Carousel</a></li>
                                                            <li><a className="dropdown-item" href="icon-concept.html">Icons - Concept</a></li>
                                                            <li><a className="dropdown-item" href="icon-duotone.html">Icons - Duotone</a></li>
                                                            <li><a className="dropdown-item" href="icon-unicons.html">Icons - Unicons</a></li>
                                                            <li><a className="dropdown-item" href="player.html">Media Player</a></li>
                                                            <li><a className="dropdown-item" href="animations.html">Scroll Animations</a></li>
                                                            <li><a className="dropdown-item" href="wrappers.html">Wrappers</a></li>
                                                            <li><a className="dropdown-item" href="wrappers-angled.html">Wrappers - Angled</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <h6 className="dropdown-header">Typography</h6>
                                                        <ul className="list-unstyled">
                                                            <li><a className="dropdown-item scroll" href="typography.html#basic">Basic Typography</a></li>
                                                            <li><a className="dropdown-item scroll" href="typography.html#alerts">Alerts</a></li>
                                                            <li><a className="dropdown-item scroll" href="typography.html#animated-text">Animated Text</a></li>
                                                            <li><a className="dropdown-item scroll" href="typography.html#blockquote">Blockquote</a></li>
                                                            <li><a className="dropdown-item scroll" href="typography.html#dropcap">Dropcap</a></li>
                                                            <li><a className="dropdown-item scroll" href="typography.html#lists">Lists</a></li>
                                                            <li><a className="dropdown-item scroll" href="typography.html#tooltip-popover">Tooltip & Popover</a></li>
                                                            <li><a className="dropdown-item scroll" href="typography.html#misc">Miscellaneous</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>                                      
                                    </li> */}
                                  
                                </ul>                                
                            </div>                            
                            <div className="navbar-other">
                                <ul className="navbar-nav flex-row align-items-center ms-auto" data-sm-skip="true">
                                    {/* <li className="nav-item dropdown language-select text-uppercase">
                                        <a className="nav-link dropdown-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">En</a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><a className="dropdown-item" href="#">En</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="#">De</a></li>
                                            <li className="nav-item"><a className="dropdown-item" href="#">Es</a></li>
                                        </ul>
                                    </li> */}
                                    {/* <li className="nav-item"><a className="nav-link" data-toggle="offcanvas-info"><i className="uil uil-info-circle"></i></a></li> */}
                                    <li className="nav-item d-lg-none">
                                        <div className="navbar-hamburger"><button className="hamburger animate plain" data-toggle="offcanvas-nav"><span></span></button></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* <div className="offcanvas-info text-inverse">
                        <button type="button" className="btn-close btn-close-white offcanvas-close offcanvas-info-close" aria-label="Close"></button>
                        <a href="index.html"><img src="../../assets/img/logo-light.png" srcset="../../assets/img/logo-light@2x.png 2x" alt="" /></a>
                        <div className="mt-4 widget">
                            <p>Sandbox is a multipurpose HTML5 template with various layouts which will be a great solution for your business.</p>
                        </div>
                        <div className="widget">
                            <h4 className="widget-title text-white mb-3">Contact Info</h4>
                            <address> Moonshine St. 14/05 <br /> Light City, London </address>
                            <a href="mailto:first.last@email.com">info@email.com</a><br /> +00 (123) 456 78 90
                        </div>
                        <div className="widget">
                            <h4 className="widget-title text-white mb-3">Learn More</h4>
                            <ul className="list-unstyled">
                                <li><a href="#">Our Story</a></li>
                                <li><a href="#">Terms of Use</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="widget">
                            <h4 className="widget-title text-white mb-3">Follow Us</h4>
                            <nav className="nav social social-white">
                                <a href="#"><i className="uil uil-twitter"></i></a>
                                <a href="#"><i className="uil uil-facebook-f"></i></a>
                                <a href="#"><i className="uil uil-dribbble"></i></a>
                                <a href="#"><i className="uil uil-instagram"></i></a>
                                <a href="#"><i className="uil uil-youtube"></i></a>
                            </nav>
                        </div>
                    </div> */}
                </header>
        {/* </Route>              */}
        </>
    );
}

export default Header
