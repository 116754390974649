import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";


function Index() {
    return (
        <>
       
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Cheap Health Insurance | Health Insurance Companies - Hiforme</title>
                <link rel="canonical" href="https://wwww.hiforme.com/" />
                <meta name="description" content="Cheap health insurance companies that offer affordable coverage to you and your family. find the best online health insurance, quotes, and plans. Get your affordable health insurance today!"></meta>
                <meta name="keywords" content="Cheap Health Insurance, Health Insurance Companies, Health Insurance for Family, Health Insurance for Business, Online Health Insurance, Health Insurance Coverage, Health Insurance Plans"/>            
            </Helmet>    */}


          <section className="wrapper bg-gradient-primary">
                    <div className="container pt-10 pt-md-14 pb-8 text-center">
                        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                        <div className="col-lg-7">
                            <figure><img className="w-auto" src="../assets/img/All-in-one-customer-engagement-platform.gif" srcset="../assets/img/All-in-one-customer-engagement-platform.gif@2x.png 2x" alt="All-in-one customer engagement platform" /></figure>
                        </div>
                       
                        <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
                            <h1 className="display-1 mb-5 mx-md-n5 mx-lg-0">All-in-one customer engagement platform</h1>
                            <p className="lead fs-lg mb-7">Accelerate time-to-value. Provide a delightful, collaborative experience for your customers.</p>
                            <span><a href='tel:+18445339911' className="btn btn-primary rounded-pill me-2">Call : (844) 533-9911</a> </span>
                            <span><Link to={{ pathname: "https://panel.didbiz.com/auth/register/" }} target="_blank" className="btn btn-primary rounded-pill me-2">Sign Up Today</Link> </span>
                          
                        </div>
                       
                        </div>
                       
                    </div>
                   
                </section>
                   
                <section className="wrapper bg-light">
                <div className="container pt-10 pt-md-10">
                    <div className="row text-center">
                    <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <h2 className="fs-16 text-uppercase text-muted mb-3">WHAT WE DO?</h2>
                        <h2 className="display-4 mb-5 px-xl-1">We offer is specifically designed to connect
                        you with customers.</h2>
                        
                    </div>
                    
                    </div>
                    
                    <div className="position-relative">
                    <div className="shape rounded-circle bg-soft-blue rellax w-16 h-16" data-rellax-speed="1" style={{bottom: '-0.5rem', right: '-2.2rem', zIndex: '0'}}></div>
                    <div className="shape bg-dot primary rellax w-16 h-17" data-rellax-speed="1" style={{top: '-0.5rem', left: '-2.5rem', zIndex: 0}}></div>
                    <div className="row gx-md-5 gy-5 text-center">
                        {/* <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                            <img src="../assets/img/icons/search-1.svg" className="svg-inject icon-svg icon-svg-md text-yellow mb-3" alt="" />
                            <h4>20%</h4>
                            <p className="mb-2">Reduction in customer churn</p>
                            <Link href="#" className="more hover link-yellow">Learn More</Link> 
                            </div>
                            
                        </div>
                        
                        </div>
                        
                        <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                            <img src="../assets/img/icons/browser.svg" className="svg-inject icon-svg icon-svg-md text-red mb-3" alt="" />
                            <h4>11%</h4>
                            <p className="mb-2">Increase in upsell accounts</p>
                            <Link href="#" className="more hover link-red">Learn More</Link> 
                            </div>
                            
                        </div>
                        
                        </div>
                        
                        <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                            <img src="../assets/img/icons/chat-2.svg" className="svg-inject icon-svg icon-svg-md text-green mb-3" alt="" />
                            <h4>25%</h4>
                            <p className="mb-2">Increase in number
                            of accounts per
                            customer success 
                            manager</p>
                            <Link href="#" className="more hover link-green">Learn More</Link> 
                            </div>
                            
                        </div>
                        
                        </div>
                        
                        <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                            <img src="../assets/img/icons/megaphone.svg" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="" />
                            <h4>26%</h4>
                            <p className="mb-2">Increase in key
                            feature usage and 
                            adoption</p>
                            <Link href="#" className="more hover link-blue">Learn More</Link> 
                            </div>
                            
                        </div>
                        
                        </div> */}
                      
                        <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                            <img src="../assets/img/Did-Numbers.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="Did Numbers" />
                            <h4>Did Numbers</h4>
                            <p className="mb-2">Our DID service delivers a
                            simple low cost approach 
                            to virtual numbers,
                            providing your company 
                            with a proxy international
                            Number.</p>
                            <Link to="/did-numbers" className="more hover link-blue">Read More</Link> 
                            </div>
                            
                        </div>
                        
                        </div>
                        <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                            <img src="../assets/img/Messaging.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="Messaging" />
                            <h4>Messaging</h4>
                            <p className="mb-2">Build seamless
                            conversations with your 
                            customers using out trusted,
                            reliable messaging 
                            solutions with DiDBiz.</p>
                            <Link to="/messaging" className="more hover link-blue">Read More</Link> 
                            </div>
                            
                        </div>
                        
                        </div>
                        <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                            <img src="../assets/img/Programmable-Voice.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="Programmable Voice" />
                            <h4>Programmable Voice</h4>
                            <p className="mb-2">Build an engaging voice
                            experience that you can
                            quickly scale and modify 
                            with a wide array of
                            customization options
                            and resources, like 
                            our Voice SDK.</p>
                            <Link to="/programming-voice" className="more hover link-blue">Read More</Link> 
                            </div>
                            
                        </div>
                        
                        </div>
                        <div className="col-md-6 col-xl-3">
                        <div className="card shadow-lg">
                            <div className="card-body">
                            <img src="../assets/img/SIP-Trunking-icon.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="SIP Trunking" />
                            <h4>SIP Trunking</h4>
                            <p className="mb-2">DiDBiz’s SIP Trunking
                            service automatically
                            scales, up or down, to
                            meet your traffic needs
                            with unlimited capacity. </p>
                            <Link to="/sip-trunking" className="more hover link-blue">Read More</Link> 
                            </div>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                </section>
                   
                <section className="wrapper bg-gradient-reverse-primary">
                <div className="container py-16 py-md-10">
                    <div className="row text-center">
                    <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <h3 className="display-4 mb-5 px-xl-1">No #1 cloud communication platform providers </h3>
                        {/* <h2 className="fs-16 text-uppercase text-muted mb-3">Customer success is more than our job. It's our calling. </h2> */}
                    </div>
                    
                    </div>
                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-8 align-items-center">
                    <div className="col-lg-7 order-lg-2">
                        <figure><img className="w-auto" src="../assets/img/cloud-communication-platform.webp" srcset="../assets/img/cloud-communication-platform.webp" alt="cloud communication platform" /></figure>
                    </div>
                    
                    <div className="col-lg-5">
                        {/* <h2 className="fs-16 text-uppercase text-muted mb-3">Analyze Now</h2> */}
                        <h3 className="display-5 mb-5">Highly buildable cloud
                        communication solutions
                        to get you connected</h3>
                        <p className="mb-2">You would need an efficient  communication platform that
                        can  bridge  the  gap  of  time  and  space.  A  good
                        communication  platform  should  be  highly  buildable,
                        scalable, secure, and also affordable.</p>
                        <p className="mb-2">DiDBiz offer cloud communication platforms which can be
                        deployed to different types of devices for different purposes.</p>
                        <p className="mb-2">Cloud  communication  platforms  have  many  benefits  –
                        especially in terms of cost – but they also come with their
                        own set  of  challenges.  The  most  common  challenge  for
                        these platforms is being able to deliver the quality of service
                        an organization expects  from a  communications  platform
                        within a budget-friendly price point.</p>
                        <div className="row">
                        <div className="col-lg-9">
                            {/* <form action="#">
                            <div className="form-floating input-group">
                                <input type="url" className="form-control" placeholder="Enter Website URL" id="seo-check"/>
                                <label for="seo-check">Enter Website URL</label>
                                <button className="btn btn-primary" type="button">Check</button>
                            </div>
                            </form> */}
                            <div className="text-center">
                            <span><Link to={{ pathname: "https://panel.didbiz.com/auth/register/" }} target="_blank" className="btn btn-primary rounded-pill me-2">Sign up</Link> </span>
                            <span ><Link to="/contact-us" className="btn btn-primary rounded-pill text-center">Talk to sales</Link> </span>
                            </div>
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                </section>

                <section className="wrapper bg-light upper-end lower-end">
                <div className="container py-2 pt-md-2 pb-md-5">
                    <h3 className="display-4 mb-5 px-xl-1 text-center mt-5">More Features For You</h3>
                    <div className="row gy-6 mb-14 mb-md-10">
                    
                    <div className="col-lg-12 pricing-wrapper">
                        <div className="row gy-6 position-relative mt-5">
                        <div className="shape bg-dot red rellax w-16 h-18" data-rellax-speed="1" style={{bottom: '-0.5rem', right: '-1.6rem'}}></div>
                       
                        <div className="col-md-4">
                            <div className="pricing card shadow-lg">
                            <div className="card-body pb-5 text-center">
                                <img src="../assets/img/DID-Phone-Numbers.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="DID Phone Numbers" />
                                <h4 className="card-title mb-1 mt-2">DID Phone Numbers</h4>
                                <p>We provide you initial USA
                                based Incoming number also as 
                                Outbound call to USA and
                                Canada.</p>                                
                            </div>                            
                            </div>                            
                        </div>
                        
                        <div className="col-md-4">
                            <div className="pricing card shadow-lg">
                            <div className="card-body pb-5 text-center">
                               <img src="../assets/img/Dynamic-Caller-ID.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="Dynamic Caller ID" />                         
                                <h4 className="card-title mb-1 mt-2">Dynamic Caller ID</h4>
                                <p>Customize your caller id for
                                outbound calls. Set any number 
                                that you want to show up on
                                the end users mobile.</p>                                
                            </div>                            
                            </div>                            
                        </div>
                        
                        <div className="col-md-4">
                            <div className="pricing card shadow-lg">
                            <div className="card-body pb-5 text-center">
                            <img src="../assets/img/Call-Recording.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="Call Recording" />
                                <h4 className="card-title mb-1 mt-2">Call Recording</h4>
                                <p>Record and store your calls
                                on our own platform for
                                complimentary. Our secure 
                                servers keep your data safe and
                                accessible anytime at any place.</p>                                
                            </div>                            
                            </div>                            
                        </div>
                                                
                        <div className="col-md-4">
                            <div className="pricing card shadow-lg">
                            <div className="card-body pb-5 text-center">
                            <img src="../assets/img/Intelligent-Call-Control.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="Intelligent Call Control" />
                                <h4 className="card-title mb-1 mt-2">Intelligent Call Control</h4>
                                <p>Our soft switch will management
                                call easily using panel and call 
                                forwarding supported rules,
                                as well as advance call routing.</p>                                
                            </div>                            
                            </div>                            
                        </div>
                   
                        <div className="col-md-4">
                            <div className="pricing card shadow-lg">
                            <div className="card-body pb-5 text-center">
                            <img src="../assets/img/Real-Time-Call-Notifications.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="Real Time Call Notifications" />
                                <h4 className="card-title mb-1 mt-2">Real Time Call Notifications</h4>
                                <p>We receive detailed data
                                including call duration, call
                                status, billing information etc.</p>                                
                            </div>                            
                            </div>                            
                        </div>
                      
                        <div className="col-md-4">
                            <div className="pricing card shadow-lg">
                            <div className="card-body pb-5 text-center">
                            <img src="../assets/img/Voicemail-Service.webp" className="svg-inject icon-svg icon-svg-md text-blue mb-3" alt="Voicemail Service" />
                                <h4 className="card-title mb-1 mt-2">Voicemail Service</h4>
                                <p>We store your all voicemail data
                                when you are not available and
                                when you get a change then 
                                you can lister your offline
                                messages.</p>                                
                            </div>                            
                            </div>                            
                        </div>
                        
                       
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                   
                    
                    <div className="px-lg-5">
                    <div className="row gx-0 gx-md-8 gx-xl-12 gy-8 align-items-center">
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c1.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c2.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c3.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c4.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c5.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c6.png" alt="" /></figure>
                        </div>                        
                    </div>                    
                    </div>
                    
                </div>
                
                </section>
                
                <section className="wrapper bg-light ">
                <div className="container py-5 pt-md-5 pb-md-2">
                    <div className="row gx-md-2 gx-xl-12 gy-10 mb-0 mb-md-10 align-items-center">
                    <div className="col-lg-6 order-lg-2">
                        <div className="card shadow-lg me-lg-6">
                        <div className="card-body p-6">
                            <div className="d-flex flex-row">
                            <div>
                                <span className="icon btn btn-circle btn-lg btn-soft-primary disabled me-4"><span className="number">01</span></span>
                            </div>
                            <div>
                                <h4 className="mb-1">20%</h4>
                                <p className="mb-0">Reduction incustomer churn</p>
                            </div>
                            </div>
                        </div>
                        
                        </div>
                        
                        <div className="card shadow-lg mt-2">
                        <div className="card-body p-6">
                            <div className="d-flex flex-row">
                            <div>
                                <span className="icon btn btn-circle btn-lg btn-soft-primary disabled me-4"><span className="number">02</span></span>
                            </div>
                            <div>
                                <h4 className="mb-1">11%</h4>
                                <p className="mb-0">Increase in upsell accounts</p>
                            </div>
                            </div>
                        </div>
                        
                        </div>

                        <div className="card shadow-lg mt-2">
                        <div className="card-body p-6">
                            <div className="d-flex flex-row">
                            <div>
                                <span className="icon btn btn-circle btn-lg btn-soft-primary disabled me-4"><span className="number">03</span></span>
                            </div>
                            <div>
                                <h4 className="mb-1">26%</h4>
                                <p className="mb-0">Increase in key
                                feature usage and 
                                adoption</p>
                            </div>
                            </div>
                        </div>
                        
                        </div>
                      
                        <div className="card shadow-lg mt-2">
                        <div className="card-body p-6">
                            <div className="d-flex flex-row">
                            <div>
                                <span className="icon btn btn-circle btn-lg btn-soft-primary disabled me-4"><span className="number">04</span></span>
                            </div>
                            <div>
                                <h4 className="mb-1">40%</h4>
                                <p className="mb-0">Reduction in cost of ownership</p>
                            </div>
                            </div>
                        </div>
                        
                        </div>

                        <div className="card shadow-lg mt-2">
                        <div className="card-body p-6">
                            <div className="d-flex flex-row">
                            <div>
                                <span className="icon btn btn-circle btn-lg btn-soft-primary disabled me-4"><span className="number">05</span></span>
                            </div>
                            <div>
                                <h4 className="mb-1">11%</h4>
                                <p className="mb-0">Increase in upsell accounts</p>
                            </div>
                            </div>
                        </div>
                        
                        </div>
                        
                     
                        
                    </div>
                    
                    <div className="col-lg-6">
                        <h3 className="display-4 mb-5">We help customer success teams get results* </h3>
                        <h2 className="fs-16 text-uppercase text-muted mb-3">Customer success is more than our job. It's our calling. </h2>
                        
                        <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Nullam quis risus eget urna mollis.</p>
                        <p className="mb-6">Nullam id dolor id nibh ultricies vehicula ut id elit. Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum nulla sed consectetur.</p>
                        <Link to="/contact-us" className="btn btn-primary rounded-pill mb-0">Contact Us</Link> 
                    </div>
                    
                    </div>
                    
                    <div className="row gx-lg-8 pt-10 gx-xl-12 gy-10 mb-lg-10 align-items-center">
                    <div className="col-lg-7">
                        <figure><img className="w-auto" src="../assets/img/cloud-communication-solutions.webp" srcset="../assets/img/cloud-communication-solutions.webp" alt="cloud communication solutions" /></figure>
                    </div>
                    
                    <div className="col-lg-5">
                        <h2 className="fs-16 text-uppercase text-muted mb-3">Why Choose Us?</h2>
                        <h3 className="display-4 mb-7">We bring solutions to make life easier.</h3>
                        <p>We  are  the  cloud  communication  platform  that  helps
                        businesses to collaborate more efficiently.</p>
                        <p>We provide an intelligent  cloud communication platform
                        that makes it easy for all the employees in the company to
                        connect  to each other.  We also help companies  get  their
                        work done with fewer resources- which ultimately reduces
                        costs and increases efficiency.</p>
                        <p>We  offer  a  variety of  solutions  to support  businesses  in
                        making life easier, including virtual meetings, collaborative
                        chat tools, and email integrations.</p>
                        <span><Link to="/contact-us" className="btn btn-primary rounded-pill me-2">Talk to an expert {'>>'}</Link> </span>
                          
                        {/* <div className="accordion accordion-wrapper" id="accordionExample">
                        <div className="card plain accordion-item">
                            <div className="card-header" id="headingOne">
                            <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Professional Design </button>
                            </div>
                            
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="card-body">
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel.</p>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="card plain accordion-item">
                            <div className="card-header" id="headingTwo">
                            <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Top-Notch Support </button>
                            </div>
                            
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="card-body">
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel.</p>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="card plain accordion-item">
                            <div className="card-header" id="headingThree">
                            <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Header and Slider Options </button>
                            </div>
                            
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="card-body">
                                <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel.</p>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        </div> */}
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                </section>
                   
                {/* <section className="wrapper bg-gradient-primary">
                <div className="container py-14 pt-md-10 pb-md-5">
                    <div className="position-relative mt-lg-n23 mt-xl-n25">
                    <div className="row text-center">
                        <div className="col-lg-6 mx-auto">
                        <h2 className="fs-16 text-uppercase text-muted mb-3">Our Team</h2>
                        <h3 className="display-4 mb-10">Think unique and be innovative. Make a difference with Sandbox.</h3>
                        </div>
                        
                    </div>
                    
                    <div className="position-relative">
                        <div className="shape bg-dot blue rellax w-16 h-17" data-rellax-speed="1" style={{bottom: '0.5rem', right: '-1.7rem', 'zIndex': 0}}></div>
                        <div className="shape rounded-circle bg-line red rellax w-16 h-16" data-rellax-speed="1" style={{top: '0.5rem', left: '-1.7rem', zIndex: 0}}></div>
                        <div className="row grid-view gy-6 gy-xl-0">
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg">
                            <div className="card-body">
                                <img className=" mb-4" src="../assets/img/avatars/te1.jpg" srcset="../assets/img/avatars/te1@2x.jpg 2x" alt="" />
                                <h4 className="mb-1">Coriss Ambady</h4>
                                <div className="meta mb-2">Financial Analyst</div>
                                <p className="mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                                <nav className="nav social mb-0">
                                <Link href="#"><i className="uil uil-twitter"></i></Link> 
                                <Link href="#"><i className="uil uil-facebook-f"></i></Link> 
                                <Link href="#"><i className="uil uil-dribbble"></i></Link> 
                                </nav>
                                
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg">
                            <div className="card-body">
                                <img className=" mb-4" src="../assets/img/avatars/te2.jpg" srcset="../assets/img/avatars/te2@2x.jpg 2x" alt="" />
                                <h4 className="mb-1">Cory Zamora</h4>
                                <div className="meta mb-2">Marketing Specialist</div>
                                <p className="mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                                <nav className="nav social mb-0">
                                <Link href="#"><i className="uil uil-twitter"></i></Link> 
                                <Link href="#"><i className="uil uil-facebook-f"></i></Link> 
                                <Link href="#"><i className="uil uil-dribbble"></i></Link> 
                                </nav>
                                
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg">
                            <div className="card-body">
                                <img className=" mb-4" src="../assets/img/avatars/te3.jpg" srcset="../assets/img/avatars/te3@2x.jpg 2x" alt="" />
                                <h4 className="mb-1">Nikolas Brooten</h4>
                                <div className="meta mb-2">Sales Manager</div>
                                <p className="mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                                <nav className="nav social mb-0">
                                <Link href="#"><i className="uil uil-twitter"></i></Link> 
                                <Link href="#"><i className="uil uil-facebook-f"></i></Link> 
                                <Link href="#"><i className="uil uil-dribbble"></i></Link> 
                                </nav>
                                
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg">
                            <div className="card-body">
                                <img className=" mb-4" src="../assets/img/avatars/te4.jpg" srcset="../assets/img/avatars/te4@2x.jpg 2x" alt="" />
                                <h4 className="mb-1">Jackie Sanders</h4>
                                <div className="meta mb-2">Investment Planner</div>
                                <p className="mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                                <nav className="nav social mb-0">
                                <Link href="#"><i className="uil uil-twitter"></i></Link> 
                                <Link href="#"><i className="uil uil-facebook-f"></i></Link> 
                                <Link href="#"><i className="uil uil-dribbble"></i></Link> 
                                </nav>
                                
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                </section> */}
{/*                    
                <section className="wrapper bg-light">
                <div className="container">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                    <div className="col-lg-7 order-lg-2">
                        <figure><img className="w-auto" src="../assets/img/concept/concept8.png" srcset="../assets/img/concept/concept8@2x.png 2x" alt="" /></figure>
                    </div>
                    
                    <div className="col-lg-5">
                        <h2 className="fs-16 text-uppercase text-muted mb-3">Our Solutions</h2>
                        <h3 className="display-4 mb-5">We make your spending stress-free for you to have the perfect control.</h3>
                        <p className="mb-6">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus.</p>
                        <div className="row gy-3">
                        <div className="col-xl-6">
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                            <li><span><i className="uil uil-check"></i></span><span>Aenean quam ornare. Curabitur blandit.</span></li>
                            <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Nullam quis risus eget urna mollis ornare.</span></li>
                            </ul>
                        </div>
                        
                        <div className="col-xl-6">
                            <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                            <li><span><i className="uil uil-check"></i></span><span>Etiam porta euismod malesuada mollis.</span></li>
                            <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Vivamus sagittis lacus vel augue rutrum.</span></li>
                            </ul>
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                </section> */}
                   
                {/* <section className="wrapper bg-gradient-reverse-primary">
                <div className="container py-14 py-md-10">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                    <div className="col-lg-7">
                        <div className="row gx-md-5 gy-5">
                        <div className="col-md-6 col-xl-5 align-self-end">
                            <div className="card shadow-lg">
                            <div className="card-body">
                                <blockquote className="icon mb-0">
                                <p>“Cum sociis natoque penatibus et magnis dis parturient montes.”</p>
                                <div className="blockquote-details">
                                    <div className="info p-0">
                                    <h5 className="mb-1">Coriss Ambady</h5>
                                    <p className="mb-0">Financial Analyst</p>
                                    </div>
                                </div>
                                </blockquote>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 align-self-end">
                            <div className="card shadow-lg">
                            <div className="card-body">
                                <blockquote className="icon mb-0">
                                <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod.”</p>
                                <div className="blockquote-details">
                                    <div className="info p-0">
                                    <h5 className="mb-1">Cory Zamora</h5>
                                    <p className="mb-0">Marketing Specialist</p>
                                    </div>
                                </div>
                                </blockquote>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-5 offset-xl-1">
                            <div className="card shadow-lg">
                            <div className="card-body">
                                <blockquote className="icon mb-0">
                                <p>“Donec id elit non porta gravida at eget metus. Duis mollis est luctus commodo nisi erat.”</p>
                                <div className="blockquote-details">
                                    <div className="info p-0">
                                    <h5 className="mb-1">Barclay Widerski</h5>
                                    <p className="mb-0">Sales Specialist</p>
                                    </div>
                                </div>
                                </blockquote>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 align-self-start">
                            <div className="card shadow-lg">
                            <div className="card-body">
                                <blockquote className="icon mb-0">
                                <p>“Nisi erat porttitor ligula, eget lacinia odio sem nec elit. Aenean eu leo pellentesque.”</p>
                                <div className="blockquote-details">
                                    <div className="info p-0">
                                    <h5 className="mb-1">Jackie Sanders</h5>
                                    <p className="mb-0">Investment Planner</p>
                                    </div>
                                </div>
                                </blockquote>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    <div className="col-lg-5">
                        <h2 className="fs-16 text-uppercase text-muted mb-3 mt-lg-n6">Our Community</h2>
                        <h3 className="display-4 mb-5">Don't take our word for it. See what customers are saying about us.</h3>
                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum. Vestibulum id ligula porta felis euismod.</p>
                        <Link href="#" className="btn btn-primary rounded-pill mt-3">All Testimonials</Link> 
                    </div>
                    
                    </div>
                    
                </div>
                
                </section> */}
                   
                <section className="wrapper bg-light upper-end lower-end">
                <div className="container py-14 pt-md-10 pb-md-0">
                    <div className="row gy-6 mb-14 mb-md-5">
                    <div className="col-lg-4">
                        <h2 className="fs-16 text-uppercase text-muted mt-lg-18 mb-3">Our Pricing</h2>
                        <h3 className="display-4 mb-3">We offer great and premium prices.</h3>
                        <p>Enjoy a free 30-day trial  and experience the full service. No credit card required!</p>
                        <Link to={{ pathname: "https://panel.didbiz.com/auth/register/" }} target="_blank" className="btn btn-primary rounded-pill mt-2 ">Sign Up Today </Link> &nbsp;
                        <a href="tel:+18445339911" className="btn btn-primary rounded-pill mt-2">(844) 533-9911 </a> 
                    </div>
                    
                    <div className="col-lg-7 offset-lg-1 pricing-wrapper">
                        {/* <div className="pricing-switcher-wrapper switcher justify-content-start justify-content-lg-end">
                        <p className="mb-0 pe-3">Monthly</p>
                        <div className="pricing-switchers">
                            <div className="pricing-switcher pricing-switcher-active"></div>
                            <div className="pricing-switcher"></div>
                            <div className="switcher-button bg-primary"></div>
                        </div>
                        <p className="mb-0 ps-3">Yearly <span className="text-red">(Save 30%)</span></p>
                        </div> */}
                        <div className="row gy-6 position-relative mt-5">
                        <div className="shape bg-dot red rellax w-16 h-18" data-rellax-speed="1" style={{bottom: '-0.5rem', right: '-1.6rem'}}></div>
                        <div className="col-md-6">
                            <div className="pricing card shadow-lg">
                            <div className="card-body pb-12">
                                <div className="prices text-dark">
                                <div className="price price-show"><span className="price-currency">$</span><span className="price-value">19</span> <span className="price-duration">month</span></div>
                                <div className="price price-hide price-hidden"><span className="price-currency">$</span><span className="price-value">199</span> <span className="price-duration">year</span></div>
                                </div>
                                
                                <h4 className="card-title mt-2">Premium Plan</h4>
                                <ul className="icon-list bullet-soft-primary mt-8 mb-9">
                                <li><i className="uil uil-check fs-21"></i><span><strong>5</strong> Projects </span></li>
                                <li><i className="uil uil-check fs-21"></i><span><strong>100K</strong> API Access </span></li>
                                <li><i className="uil uil-check fs-21"></i><span><strong>200MB</strong> Storage </span></li>
                                <li><i className="uil uil-check fs-21"></i><span> Weekly <strong>Reports</strong></span></li>
                                <li><i className="uil uil-check fs-21"></i><span> 7/24 <strong>Support</strong></span></li>
                                </ul>
                                <Link href="#" className="btn btn-primary rounded-pill">Choose Plan</Link> 
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 popular">
                            <div className="pricing card shadow-lg">
                            <div className="card-body pb-12">
                                <div className="prices text-dark">
                                <div className="price price-show"><span className="price-currency">$</span><span className="price-value">49</span> <span className="price-duration">month</span></div>
                                <div className="price price-hide price-hidden"><span className="price-currency">$</span><span className="price-value">499</span> <span className="price-duration">year</span></div>
                                </div>
                                
                                <h4 className="card-title mt-2">Corporate Plan</h4>
                                <ul className="icon-list bullet-soft-primary mt-8 mb-9">
                                <li><i className="uil uil-check fs-21"></i><span><strong>20</strong> Projects </span></li>
                                <li><i className="uil uil-check fs-21"></i><span><strong>300K</strong> API Access </span></li>
                                <li><i className="uil uil-check fs-21"></i><span><strong>500MB</strong> Storage </span></li>
                                <li><i className="uil uil-check fs-21"></i><span> Weekly <strong>Reports</strong></span></li>
                                <li><i className="uil uil-check fs-21"></i><span> 7/24 <strong>Support</strong></span></li>
                                </ul>
                                <Link href="#" className="btn btn-primary rounded-pill">Choose Plan</Link> 
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                    <div className="row gx-lg-8 gx-xl-12 gy-10 mt-5 mb-5 mb-md-5 align-items-center">
                    <div className="col-lg-7">
                        <figure><img className="w-auto" src="../assets/img/concept/concept5.png" srcset="../assets/img/concept/concept5@2x.png 2x" alt="Got any questions" /></figure>
                    </div>
                    
               
                    <div className="col-lg-5">
                        <h3 className="display-4 mb-7">Got any questions? Don't hesitate to get in touch.</h3>
                        <div className="d-flex flex-row">
                        <div>
                            <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
                        </div>
                        <div>
                            <h5 className="mb-1">Address</h5>
                            <address>1598 Gould St. <br className="d-none d-md-block" />Dallas, Texas</address>
                        </div>
                        </div>
                        <div className="d-flex flex-row">
                        <div>
                            <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-phone-volume"></i> </div>
                        </div>
                        <div>
                            <h5 className="mb-1">Phone</h5>
                            <p><a href='tel:8445339911'>(844) 533-9911</a> </p>
                        </div>
                        </div>
                        <div className="d-flex flex-row">
                        <div>
                            <div className="icon text-primary fs-28 me-4 mt-n1"> <i className="uil uil-envelope"></i> </div>
                        </div>
                        <div>
                            <h5 className="mb-1">E-mail</h5>
                            <p className="mb-0"><a href="mailto:info@didbiz.com" className="link-body">info@didbiz.com</a> </p>
                        </div>
                        </div>
                    </div>
                    
                    </div>
{/*                     
                    <div className="px-lg-5">
                    <div className="row gx-0 gx-md-8 gx-xl-12 gy-8 align-items-center">
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c1.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c2.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c3.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c4.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c5.png" alt="" /></figure>
                        </div>
                        
                        <div className="col-4 col-md-2">
                        <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src="../assets/img/brands/c6.png" alt="" /></figure>
                        </div>
                        
                    </div>
                    
                    </div> */}
                    
                </div>
                
                </section>
             
                <section className="wrapper bg-purple text-center mt-5">
                <div className="py-5 py-md-10">
                    <div className="row">
                        <div className="col-xl-10 mx-auto">
                            <div className="card bg-purple">
                                <div className="card-body">
                                    <h2 className="fs-40 text-white">Get started today for free!</h2>
                                    <p className="text-white fs-25 text-center">Create your account and receive trial credits or get in touch with
                                        us</p><Link to={{ pathname: "https://panel.didbiz.com/auth/register/" }} target="_blank" className="btn btn-primary rounded mb-0 text-nowrap">Sign Up Today </Link>  &nbsp;
                                        <Link
                                        to="/contact-us" className="btn btn-primary rounded mb-0 text-nowrap">CONTACT SALES</Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
              
        </>
    );
}

export default Index;