import logo from './logo.svg';
import './App.css';
import * as All from './Export'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React, {Fragment} from 'react';

const Routing = () => {
    return(
      <>
      <Switch>
      <Route exact path="/" component={All.Index}/>
      <Route exact path="/blog" component={All.blog}/>

      <Route exact path="/blog/:slug/" component={All.post}/>
              
      <Route exact path="/contact-us" component={All.Contact}/>
      
      <Route exact path="/did-numbers" component={All.didNumber}/>
      <Route exact path="/messaging" component={All.messaging}/>
      <Route exact path="/programming-voice" component={All.programmingVoice}/>
      <Route exact path="/sip-trunking" component={All.sipTrunking}/>
      
{/* 
      <Route path='/sign-up' component={() => { 
          window.location.href = 'https://panel.didbiz.com/auth/register'; 
          return null;
      }}/> */}

      
      <Route exact path="*" component={All.PageNotFound}/>
      </Switch>
      
      </>
  )
}



// function App() {
//   return (
//     <div className="content-wrappe">
//       <Route>
//       <Route>
//       <All.Header/>
//       <Routing/>
//       <All.Footer/>
//       </Route>
//       </Route>
//     </div>
//   );
// }


function App() {
  return (
       <Router>
        <div class="content-wrapper">
          <All.Header/>
          <Routing/>
          <All.Footer/>
        </div>
      </Router>      
  );
}

export default App;
