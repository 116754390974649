import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";


function messaging(props) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Messaging API | API for Sending Text Messages | DiDBiz.com</title>
                <link rel="canonical" href="https://www.didbiz.com/messaging" />
                <meta name="description" content="DiDBiz.com provides messaging API that allows you to sending text messages to any phone number in the world."></meta>
                <meta name="keywords" content="Messaging API, API for Sending Text Messages, Brand Messaging, Text Messaging API, Instant Messaging API"/>            
            </Helmet> 

           <section className="wrapper didnumber-bg" style={{ 
            backgroundImage: `url("../assets/img/Messaging-API.webp")`,
            // backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            }}>
                <div className="container py-14 pt-md-15 text-white">
                    <div className="row text-center">
                        <div className="col-lg-9 col-xxl-7 mx-auto" data-group="welcome" data-interval="-200" >
                            <h1 className="display-1 mb-4 text-white">Messaging API </h1>
                            <p className="lead fs-24 lh-sm px-md-5 px-xl-15 px-xxl-10 mb-7">The integration with the messaging API allows for more efficient communication between 
                            the customer and company through text messages as well as voice
                            </p>
                        </div>                        
                    </div>
                    
                    <div className="d-flex justify-content-center" data-group="join" data-delay="900">
                        <span><Link className="btn btn-lg btn-primary rounded-pill mx-1">Free Trial</Link> </span>
                        <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Contact Us</Link> </span>
                    </div>

                    
                </div>
                
            </section>
            
            <br/>
            <section className="wrapper bg-light">
                <div className="container justify-content-center  mb-md-12">
                    <h2 className="display-3 px-xl-10 text-center ">What is the Messaging API? </h2>
                    <p className="text-justify ">
                    A messaging API is an application programming interface that provides developers with the ability
                    to create messaging applications such as Facebook Messenger, Apple iMessage, WhatsApp, Slack
                    and more.
                    </p>
                    <p className="text-justify">A messaging API is a software component that allows developers to integrate chat features in their
                    apps. It is used by various popular messaging services including Facebook Messenger, Apple
                    iMessage, WhatsApp and Slack</p>
                    <p className="text-justify">A new trend in the world of technology is to use these APIs for enterprise solutions for different
                    industries.</p>                    

                </div>

            </section>
            <section className="wrapper bg-light">
                <div className="container justify-content-center  mb-md-12">
                    <h2 className="display-3 px-xl-10 text-center ">Best Messaging Solution for Your Customers</h2>
                    <div className="row gx-md-5 gy-5 pt-5 text-center">
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">

                                    <img className="" src="../assets/img/Reach-customers-directly.webp" alt="Reach customers directly"/>
                                    <h4 className="pt-2">Reach customers directly </h4>
                                    <p className="mb-2">
                                    Send messages straight to your
                                    customer's inbox, letting them engage
                                    on their own terms.
                                    </p>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    
                                    <img className="" src="../assets/img/Personalize-conversations.webp" alt="Personalize conversations"/>
                                    <h4 className="pt-2">Personalize conversations</h4>
                                    <p className="mb-2">Create unique and relevant content for
                                    each and every individual customer.
                                    Meet their needs - and make their day! </p>

                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="" src="../assets/img/Automate-engagement.webp" alt="Automate engagement"/>
                                    <h4 className="pt-2">Automate engagement</h4>
                                    <p className="mb-2">With Sinch, automating messages is
                                    easy and cost-effective - giving you
                                    more time to focus on other priorities! </p>

                                </div>
                                
                            </div>
                            
                        </div>
                        

                    </div>
                    <div className="d-flex justify-content-center pt-md-10 " data-group="join" data-delay="900">
                        
                    <span><Link to={{ pathname: "https://panel.didbiz.com/auth/register/" }} target="_blank"  className="btn btn-lg btn-outline-primary rounded-pill mx-1">Sign Up Today</Link> </span>
                    </div>

                </div>

            </section>
            <section id="snippet-4" className="wrapper bg-light wrapper-border">
                <div className="container pt-5 pt-md-10 pb-13 pb-md-10">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                        <div className="col-lg-6">

                            <h3 className="display-4 mb-5">Supernetwork for true global reach
                            </h3>
                            <p>When you need to reach a large audience on the planet,
                            DidBiz is your go-to. With 600+ direct operator connections
                            and an unmatched range of messaging channels - we have
                            been trusted by more than 3 thousand enterprise customers
                            worldwide!</p>
                            <p>Reach anyone anywhere at anytime with just one phone
                            number: it's that simple thanks in part due our 100%
                            consumer penetration rate across any mobile channel!
                             </p>
                             <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Talk to an expert</Link> </span>
                        </div>

                        <div className="col-lg-6">
                            <img src="../assets/img/Supernetwork-for-true-global-reach.webp" className="img-fluid" alt="Supernetwork for true global reach"/>
                        </div>
                        
                    </div>
                    
                </div>

            </section>

            <section className="wrapper bg-light">
                <div className="container pb-3 pb-lg-3">

                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-10 ">
                        <div className="col-lg-6">
                            <img src="../assets/img/High-security-standards.webp" className="img-fluid" alt="High security standards"/>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="display-4 mt-xxl-8 mb-3 text-justify">High security standards
                            </h3>
                            <p className="lead fs-lg lh-sm mb-6">We’re committed to your data and give you the peace of
                            mind that comes with knowing we have measures in place
                            for any situation.</p>
                            <p className="lead fs-lg lh-sm mb-6">With our sophisticated measures in place, you can rest easy
                            knowing that your data is safe. We are GDPR compliant and
                            MEF code of conduct approved which means we have all
                            the certifications necessary for an ISO27001 seal of
                            approval too!</p>
                            <div className="d-flex justify-content  " data-group="join" data-delay="900">
                                
                                <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Talk to an expert</Link> </span>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        
            <section className="wrapper bg-light">
                <div className="container pb-3 pb-lg-3">

                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-10 ">
                        <div className="col-lg-6">
                            <h3 className="display-4 mt-xxl-8 mb-3 text-justify">World-class support
                            </h3>
                            <p className="lead fs-lg lh-sm mb-6">We are not just a company that sells you products, we help
                            transform customer journeys and enrich your brand
                            experience. Whether it be consulting services or managed
                            service options for our clients; no matter what stage of
                            development they're at-you can count on us!</p>
                            <p className="lead fs-lg lh-sm mb-6">We're not just a company that sells you products, we work
                            with our clients to help them succeed! The expert team here
                            at DidBiz can transform customer journeys and enrich their
                            brand experience. </p>
                            <div className="d-flex justify-content  " data-group="join" data-delay="900">
                                
                                <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Talk to an expert</Link> </span>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <img src="../assets/img/World-class-support.webp" className="img-fluid" alt="World class support"/>
                        </div>
                    </div>
                </div>

            </section>


            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400"
                        data-image-src="../assets/img/photos/bg2.jpg"
                        >
                        <div className="pt-4">
                            <h3 className="text-white text-center">Start free Messaging API trial today!</h3>
                            <p className=" text-white text-center">The future of communications starts here. We want to help you pave the way.</p>
                            <div className="d-flex justify-content-center pb-5  " data-group="join" data-delay="900">
                                
                                <span><Link className="btn btn-sm btn-outline-primary rounded-pill mx-1">TRY IT FREE</Link> </span>
                                <span><Link to="/contact-us" className="btn btn-sm btn-outline-primary rounded-pill mx-1">TALK TO AN EXPERT</Link> </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        
        </>
    );
}

export default messaging;