import React,{useEffect,useState} from 'react';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { css } from "@emotion/react";
import SyncLoader from "react-spinners/SyncLoader";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    text-align:center;
    padding:20px;
`;


const Blog = () => {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [blogdata, setblogdata] = useState([])
    const [items, setitems] = useState([])
    let [loading, setLoading] = useState(false);


    useEffect(() => {
        getData();
    }, [])
    
    useEffect(() => {
        const endOffset = itemOffset + 5;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / 5));
        }, [itemOffset, 5]);

        const getData= async()=>{
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            await fetch('https://webapi.didbiz.com/getAllBlogs', {
                method:"POST",
                headers: myHeaders,
                // body: JSON.stringify({})
                
            }).then(response => response.json()).then(res =>{
                if(res.status == 201){   
                    // console.log(res.data);
                    setblogdata(res.data)
                    let arr = [];
                    for (const key in res.data) {
                        arr.push(key);
                    }
                    setitems(arr);
                    // console.log(arr);
                    const endOffset = itemOffset + 5;
                    setCurrentItems(arr.slice(itemOffset, endOffset));
                    setPageCount(Math.ceil(arr.length / 5));
                }
            }).catch(err => console.log(err));
            setLoading(false);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * 5) % items.length;
        console.log(
          `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
      };
    //   console.log(blogdata)

    return (
        <>   

      {/* <Items currentItems={currentItems} /> */}

      <section className="wrapper bg-soft-primary">
        <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
            <div className="row">
            <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                <h1 className="display-1 mb-3">Blog</h1>
                {/* <p className="lead px-lg-5 px-xxl-8">Welcome to our journal. Here you can find the latest company news and business articles.</p> */}
            </div>
            
            </div>
            
        </div>
        
        </section>
        
      {currentItems &&
        currentItems.map((item) => (
            
          <div>
            {/* <h3>Item #{item}</h3> */}

            <section className="wrapper bg-light">  
            <div className="container pb-5 pb-md-5 mb-15">
                <div className="row">
                <div className="col-lg-10 mx-auto">
                    <div className="blog classic-view mt-n17">
                    <article className="post">
                        <div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link className="link-dark" to={"/blog/"+blogdata[item].slug}>
                            <img src={'https://www.rayvat.com/admin/assets/images/blog/'+blogdata[item].Image} alt={blogdata[item].Image.replace(/.jpg/g,"").replace(/.webp/g,"").replace(/-/g," ")} /></Link>
                            <figcaption>
                            <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <div className="post-header">
                            {/* <div className="post-category text-line">
                                <a href="#" className="hover" rel="category">Teamwork</a>
                            </div> */}
                            
                            <h2 className="post-title mt-1 mb-0"><Link className="link-dark" to={"/blog/"+blogdata[item].slug}>{blogdata[item].page_title}</Link></h2>
                            </div>
                           
                            <div className="post-content">
                                <div dangerouslySetInnerHTML={{ __html:blogdata[item].content.replace("class=", 'className=').split("</p>")[0]+'</p>' }} />
                            </div>
                                                       
                        </div>
                        
                        <div className="card-footer">
                            <ul className="post-meta d-flex mb-0">
                            <li className="post-date"><i className="uil uil-calendar-alt"></i><span>
                            <Moment format="YYYY/MM/DD HH:mm:ss">
                                {blogdata[item].date}
                            </Moment></span></li>
                            {/* <li className="post-author"><a href="#"><i className="uil uil-user"></i><span>By Sandbox</span></a></li>
                            <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>3<span> Comments</span></a></li>
                            <li className="post-likes ms-auto"><a href="#"><i className="uil uil-heart-alt"></i>3</a></li> */}
                            </ul>
                            
                        </div>
                        
                        </div>
                        
                    </article>
                    </div>
                    </div>
                    </div>
                    </div>
            </section>
          </div>
        ))
        
        }

    

      
      { 
        !loading ?
        <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-4'>
            <ReactPaginate
            // ref={pagination}
            pageCount={pageCount}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            pageLinkClassName="page-link"
            breakLinkClassName="page-link"
            nextLinkClassName="page-link"
            previousLinkClassName="page-link"
            pageClassName="page-item"
            breakClassName="page-item"
            nextClassName="page-item"
            previousClassName="page-item"
            previousLabel={<>&laquo;</>}
            nextLabel={<>&raquo;</>}
        />
        </div>
        <div className='col-md-4'></div>
      </div>
        : <SyncLoader css={override} size={10} />
        }      


      
      {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      /> */}

          
            {/* <section className="wrapper bg-light">  
            <div className="container pb-14 pb-md-16">
                <div className="row">
                <div className="col-lg-10 mx-auto">
                    <div className="blog classic-view mt-n17">
                    <article className="post">
                        <div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><a className="link-dark" href="blog-post.html"><img src="assets/img/photos/b1.jpg" alt="" /></a>
                            <figcaption>
                            <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                        </figure>
                        <div className="card-body">
                            <div className="post-header">
                            <div className="post-category text-line">
                                <a href="#" className="hover" rel="category">Teamwork</a>
                            </div>
                            
                            <h2 className="post-title mt-1 mb-0"><a className="link-dark" href="blog-post.html">Amet Dolor Bibendum Parturient Cursus</a></h2>
                            </div>
                            
                            <div className="post-content">
                            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam quis risus eget urna mollis ornare vel. Nulla vitae elit libero, a pharetra augue. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh. Cras mattis consectetur purus sit amet fermentum. Sed posuere consectetur.</p>
                            </div>
                            
                        </div>
                        
                        <div className="card-footer">
                            <ul className="post-meta d-flex mb-0">
                            <li className="post-date"><i className="uil uil-calendar-alt"></i><span>5 Jul 2021</span></li>
                            <li className="post-author"><a href="#"><i className="uil uil-user"></i><span>By Sandbox</span></a></li>
                            <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>3<span> Comments</span></a></li>
                            <li className="post-likes ms-auto"><a href="#"><i className="uil uil-heart-alt"></i>3</a></li>
                            </ul>
                            
                        </div>
                        
                        </div>
                        
                    </article> */}
                    
                  
                    {/* <article className="post">
                        <div className="card">
                        <div className="card-img-top">
                            <div className="player" data-plyr-provider="youtube" data-plyr-embed-id="j_Y2Gwaj7Gs"></div>
                        </div>
                        <div className="card-body">
                            <div className="post-header">
                            <div className="post-category text-line">
                                <a href="#" className="hover" rel="category">Workspace</a>
                            </div>
                            
                            <h2 className="post-title mt-1 mb-0"><a className="link-dark" href="blog-post.html">Consectetur Bibendum Sollicitudin Vulputate</a></h2>
                            </div>
                            
                            <div className="post-content">
                            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam quis risus eget urna mollis ornare vel. Nulla vitae elit libero, a pharetra augue. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh. Cras mattis consectetur purus sit amet fermentum. Sed posuere consectetur.</p>
                            </div>
                            
                        </div>
                        
                        <div className="card-footer">
                            <ul className="post-meta d-flex mb-0">
                            <li className="post-date"><i className="uil uil-calendar-alt"></i><span>18 May 2021</span></li>
                            <li className="post-author"><a href="#"><i className="uil uil-user"></i><span>By Sandbox</span></a></li>
                            <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>8<span> Comments</span></a></li>
                            <li className="post-likes ms-auto"><a href="#"><i className="uil uil-heart-alt"></i>6</a></li>
                            </ul>
                            
                        </div>
                        
                        </div>
                        
                    </article>
                     */}
                    {/* </div>
                    
                    <div className="blog grid grid-view">
                    <div className="row isotope gx-md-8 gy-8 mb-8"> */}
                        {/* <article className="item post col-md-6">
                        <div className="card">
                            <figure className="card-img-top overlay overlay1 hover-scale"><a href="#"> <img src="assets/img/photos/b4.jpg" alt="" /></a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                            </figure>
                            <div className="card-body">
                            <div className="post-header">
                                <div className="post-category text-line">
                                <a href="#" className="hover" rel="category">Coding</a>
                                </div>
                                
                                <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="blog-post.html">Ligula tristique quis risus</a></h2>
                            </div>
                            
                            <div className="post-content">
                                <p>Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor. Aenean lacinia bibendum nulla sed.</p>
                            </div>
                            
                            </div>
                            
                            <div className="card-footer">
                            <ul className="post-meta d-flex mb-0">
                                <li className="post-date"><i className="uil uil-calendar-alt"></i><span>14 Apr 2021</span></li>
                                <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>4</a></li>
                                <li className="post-likes ms-auto"><a href="#"><i className="uil uil-heart-alt"></i>5</a></li>
                            </ul>
                            
                            </div>
                            
                        </div>
                        
                        </article>
                        
                        <article className="item post col-md-6">
                        <div className="card">
                            <figure className="card-img-top overlay overlay1 hover-scale"><a href="#"> <img src="assets/img/photos/b5.jpg" alt="" /></a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                            </figure>
                            <div className="card-body">
                            <div className="post-header">
                                <div className="post-category text-line">
                                <a href="#" className="hover" rel="category">Workspace</a>
                                </div>
                                
                                <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="blog-post.html">Nullam id dolor elit id nibh</a></h2>
                            </div>
                            
                            <div className="post-content">
                                <p>Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor. Aenean lacinia bibendum nulla sed.</p>
                            </div>
                            
                            </div>
                            
                            <div className="card-footer">
                            <ul className="post-meta d-flex mb-0">
                                <li className="post-date"><i className="uil uil-calendar-alt"></i><span>29 Mar 2021</span></li>
                                <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>3</a></li>
                                <li className="post-likes ms-auto"><a href="#"><i className="uil uil-heart-alt"></i>3</a></li>
                            </ul>
                            
                            </div>
                            
                        </div>
                        
                        </article>
                        
                        <article className="item post col-md-6">
                        <div className="card">
                            <figure className="card-img-top overlay overlay1 hover-scale"><a href="#"> <img src="assets/img/photos/b6.jpg" alt="" /></a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                            </figure>
                            <div className="card-body">
                            <div className="post-header">
                                <div className="post-category text-line">
                                <a href="#" className="hover" rel="category">Meeting</a>
                                </div>
                                
                                <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="blog-post.html">Ultricies fusce porta elit</a></h2>
                            </div>
                            
                            <div className="post-content">
                                <p>Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor. Aenean lacinia bibendum nulla sed.</p>
                            </div>
                            
                            </div>
                            
                            <div className="card-footer">
                            <ul className="post-meta d-flex mb-0">
                                <li className="post-date"><i className="uil uil-calendar-alt"></i><span>26 Feb 2021</span></li>
                                <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>6</a></li>
                                <li className="post-likes ms-auto"><a href="#"><i className="uil uil-heart-alt"></i>3</a></li>
                            </ul>
                            
                            </div>
                            
                        </div>
                        
                        </article>
                        
                        <article className="item post col-md-6">
                        <div className="card">
                            <figure className="card-img-top overlay overlay1 hover-scale"><a href="#"> <img src="assets/img/photos/b7.jpg" alt="" /></a>
                            <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                            </figure>
                            <div className="card-body">
                            <div className="post-header">
                                <div className="post-category text-line">
                                <a href="#" className="hover" rel="category">Business Tips</a>
                                </div>
                                
                                <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="blog-post.html">Morbi leo risus porta eget</a></h2>
                            </div>
                            <div className="post-content">
                                <p>Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor. Aenean lacinia bibendum nulla sed.</p>
                            </div>
                            
                            </div>
                            
                            <div className="card-footer">
                            <ul className="post-meta d-flex mb-0">
                                <li className="post-date"><i className="uil uil-calendar-alt"></i><span>7 Jan 2021</span></li>
                                <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>2</a></li>
                                <li className="post-likes ms-auto"><a href="#"><i className="uil uil-heart-alt"></i>5</a></li>
                            </ul>
                            
                            </div>
                            
                        </div>
                        
                        </article> */}
                        
                    {/* </div>
                    
                    </div>
                  
                </div>
                
                </div>
                
            </div>
            
            </section>` */}
                
        </>
    );
}

export default Blog;