import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

function programmingVoice(props) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Programmable Voice | Voice API for Web and Mobile | DiDBiz.com</title>
                <link rel="canonical" href="https://www.didbiz.com/programming-voice" />
                <meta name="description" content="Programmable Voice is a voice API for web and mobile. DiDBiz.com helps you build voice apps for your website, app, or business with our API & Voice Portal."></meta>
                <meta name="keywords" content="Programmable Voice, Voice API for Web, Voice API for Mobile, Programmable Voice API, Voice API for Developer"/>            
            </Helmet> 
           <section className="wrapper didnumber-bg" style={{ 
            backgroundImage: `url("../assets/img/Programmable-Voice-banner.webp")`,
            // backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            }}>
                <div className="container py-14 pt-md-15 text-white">
                    <div className="row text-center">
                        <div className="col-lg-9 col-xxl-7 mx-auto" data-group="welcome" data-interval="-200" >
                            <h1 className="display-1 mb-4 text-white">Programmable Voice</h1>
                            <p className="lead fs-24 lh-sm px-md-5 px-xl-15 px-xxl-10 mb-7">Programmable Voice refers to the software that allows you make, 
                            receive and control calls with your voice.
                            </p>
                        </div>                        
                    </div>
                    
                    <div className="d-flex justify-content-center" data-group="join" data-delay="900">
                        <span><Link className="btn btn-lg btn-primary rounded-pill mx-1">Try it FREE</Link> </span>
                        <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Contact Us</Link> </span>
                    </div>

                    
                </div>
                
            </section>
            
            <br/>
            <section className="wrapper bg-light">
                <div className="container justify-content-center  mb-md-12">
                    <h2 className="display-3 px-xl-10 text-center ">What is Programmable Voice?  </h2>
                    <p className="text-justify ">
                    Programmable Voice is an AI-powered solution that enables users to create and manage their own
                    voice assistant by combining artificial intelligence with conversational dialogue system.
                    </p>
                    <p className="text-justify">Programmable Voice is an AI-powered solution that enables users to create and manage their own
                    voice assistant by combining artificial intelligence with conversational dialogue system. The service
                    provides natural language processing and machine learning, which can be used for enterprise
                    solutions including customer service, banking, and healthcare.</p>                    

                </div>

            </section>
            <section className="wrapper bg-light">
                <div className="container justify-content-center  mb-md-12">
                    <h2 className="display-3 px-xl-10 text-center ">Advanced Programmable Voice API </h2>
                    <p className='text-center'>Programmable Voice refers to the ability to make, receive, control, and monitor calls via software,
                    in this case, voice APIs. </p>
                    <div className="row gx-md-5 gy-5 pt-5 text-center">
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg">
                                <div className="card-body">

                                    <img className="" src="../assets/img/Call-Center.webp" alt="Call Center"/>
                                    <h4 className="pt-2">Call Center  </h4>
                                    <p className="mb-2">
                                    DidBiz is an all-inclusive
                                    contact center solution that
                                    allows you to manage your
                                    calls, agents and locations
                                    with ease.
                                    </p>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    
                                    <img className="" src="../assets/img/Masked-Numbers.webp" alt="Masked Numbers"/>
                                    <h4 className="pt-2">Masked Numbers </h4>
                                    <p className="mb-2">With this app, you can
                                    transfer and connect calls
                                    without revealing your
                                    personal phone numbers of
                                    potential buyers.
                                    </p>

                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="" src="../assets/img/Analytics.webp" alt="Analytics"/>
                                    <h4 className="pt-2">Analytics</h4>
                                    <p className="mb-2">By tracking the number of
                                    interested buyers who request
                                    call-back, you can see how
                                    many people are ready and
                                    willing to buy.</p>

                                </div>
                                
                            </div>
                            
                        </div>
                     
                        <div className="col-md-6 col-xl-3">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="" src="../assets/img/Call-Routing.webp" alt="Call Routing"/>
                                    <h4 className="pt-2">Call Routing</h4>
                                    <p className="mb-2">With DidBiz, you can
                                    increase lead conversion by
                                    instantly connecting buyers
                                    with the right agents or
                                    business partners.</p>

                                </div>
                                
                            </div>
                            
                        </div>
                        

                    </div>
                    <div className="d-flex justify-content-center pt-md-10 " data-group="join" data-delay="900">
                        
                        <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">TALK TO AN EXPERT</Link> </span>
                    </div>

                </div>

            </section>
            <section id="snippet-4" className="wrapper bg-light wrapper-border">
                <div className="container pt-5 pt-md-10 pb-13 pb-md-10">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                        <div className="col-lg-6">

                            <h3 className="display-4 mb-5">Make and receive calls seamlessly
                            within your apps
                            </h3>
                            <p>Add voice to your existing apps for a more engaging and
                            personal experience.</p>
                            <p>The DidBiz Voice APIs let you programmatically customize
                            your apps to make and receive calls, listen through recorded
                            messages or handle voicemail. You can also trigger
                            webhooks for more in-depth information on what is going
                            within the system with these new functions!

                             </p>
                             <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Talk to an expert</Link> </span>
                        </div>

                        <div className="col-lg-6">
                            <img src="../assets/img/Make-and-receive-calls-seamlessly-within-your-apps.webp" className="img-fluid" alt="Make and receive calls seamlessly
                            within your apps"/>
                        </div>
                        
                    </div>
                    
                </div>

            </section>

            <section className="wrapper bg-light">
                <div className="container pb-3 pb-lg-3">

                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-10 ">
                        <div className="col-lg-6">
                            <img src="../assets/img/Route-and-manage-calls-dynamically-in-real-time.webp" className="img-fluid" alt="Route and manage calls dynamically in real-time"/>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="display-4 mt-xxl-8 mb-3 text-justify">Route and manage calls dynamically in real-time
                            </h3>
                            <p className="lead fs-lg lh-sm mb-6">Monitor and control calls from your applications with a
                            single click.
                            for any situation.</p>
                            <p className="lead fs-lg lh-sm mb-6">The ability to route calls seamlessly and automatically
                            between different agents based on your business rules is a
                            major advantage of using the Programmable Voice API</p>
                            <p className="lead fs-lg lh-sm mb-6">In just one call, you can add this feature as well any other
                            phone related functions such as making international or
                            cellular long distance number dialing available for use
                            within applications with no need whatsoever in writing
                            custom code!</p>
                            <div className="d-flex justify-content  " data-group="join" data-delay="900">
                                
                                <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Talk to an expert</Link> </span>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        
            <section className="wrapper bg-light">
                <div className="container pb-3 pb-lg-3">

                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-10 ">
                        <div className="col-lg-6">
                            <h3 className="display-4 mt-xxl-8 mb-3 text-justify">Integrate AI and Machine Learning
                            systems
                            </h3>
                            <p className="lead fs-lg lh-sm mb-6">With a new, innovative platform for managing calls that
                            goes beyond traditional constraints.</p>
                            <p className="lead fs-lg lh-sm mb-6">AI and machine learning are used in a wide range of
                            applications to help us make sense out information. For
                            example, AI can be put into use for insights into important
                            trends or real-time training so that you know when your
                            employees need an update on new products they're working
                            with--allowing them time prepare accordingly instead
                            wasting precious hours trying figure it out themselves!
                            </p>
                            <div className="d-flex justify-content  " data-group="join" data-delay="900">
                                
                                <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Talk to an expert</Link> </span>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <img src="../assets/img/Integrate-AI-and-Machine-Learning-systems.webp" className="img-fluid" alt="Integrate AI and Machine Learning
                            systems"/>
                        </div>
                    </div>
                </div>

            </section>


            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400"
                        data-image-src="../assets/img/photos/bg2.jpg"
                        >
                        <div className="pt-4">
                            <h3 className="text-white text-center">Start your programmable voice API free trial today!</h3>
                            <p className=" text-white text-center">Start making calls with our free Voice API trial, or talk to one of 
                            our experts to get your questions answered.</p>
                            <div className="d-flex justify-content-center pb-5  " data-group="join" data-delay="900">
                                
                                <span><Link className="btn btn-sm btn-outline-primary rounded-pill mx-1">Try it FREE</Link> </span>
                                <span><Link to="/contact-us" className="btn btn-sm btn-outline-primary rounded-pill mx-1">Contact Us</Link> </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        
        </>
    );
}

export default programmingVoice;