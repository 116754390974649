import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

function didNumber(props) {
    return (
        <>
             <Helmet>
                <meta charSet="utf-8" />
                <title>DiD Numbers | Purchase Did Number Tollfree | DiDBiz.com</title>
                <link rel="canonical" href="https://www.didbiz.com/did-numbers" />
                <meta name="description" content="DiDBiz.com is the best place to buy toll-free DID numbers and DID prefixes in bulk. We offer a large selection of DID numbers at the lowest prices on the market, with top quality service and fast delivery."></meta>
                <meta name="keywords" content="DiD Numbers, Purchase Did Number Tollfree, Free Did Number, Toll-Free DID Numbers"/>            
            </Helmet> 

           <section className="wrapper didnumber-bg" style={{ 
            backgroundImage: `url("../assets/img/A-DID-number-makes-it-quick-and-easy-to-connect-your-business-with-customers-all-around-the-world.webp")`,
            // backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            }}>
                <div className="container py-14 pt-md-15 text-white">
                    <div className="row text-center">
                        <div className="col-lg-9 col-xxl-7 mx-auto" data-group="welcome" data-interval="-200" >
                            <h1 className="display-1 mb-4 text-white">Did Numbers </h1>
                            <p className="lead fs-24 lh-sm px-md-5 px-xl-15 px-xxl-10 mb-7">A DID number makes it quick and easy
                                to connect your business with customers all around the world.
                            </p>
                        </div>                        
                    </div>
                    
                    <div className="d-flex justify-content-center" data-group="join" data-delay="900">
                        <span><Link to="#" className="btn btn-lg btn-primary rounded-pill mx-1">Pricing Plans</Link> </span>
                        <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Contact Us</Link> </span>
                    </div>

                    
                </div>
                
            </section>
            
            <br/>
            <section className="wrapper bg-light">
                <div className="container justify-content-center  mb-md-12">
                    <h2 className="display-3 px-xl-10 text-center ">What is a DID Number? </h2>
                    <p className="text-center ">Use DID Phone Numbers to Create a Local Presence.
                    </p>
                    <br/>
                    <p className="text-justify">Did Number is a phone number that lets you make and receive calls from your
                        existing phone number. </p>
                    <p className="text-justify">The Did Number app gives you the option to choose from different numbers and
                        talk with other people on the other side of the conversation. You can use this app to hide your real
                        number when making calls so that you don't show up on caller ID or if you want to get in touch with
                        somebody who has only provided a phone number and not an email address. </p>
                    <p className="text-justify">DID numbers makes it easier for business to monitor the calls for performance
                        analysis – track the number of calls missed, the turnaround time of an agent, time spent on call and
                        customer call recordings. </p>

                </div>

            </section>
            <section className="wrapper bg-light">
                <div className="container justify-content-center  mb-md-12">
                    <h2 className="display-3 px-xl-10 text-center ">How It Works - 3 Simple Steps</h2>
                    <div className="row gx-md-5 gy-5 pt-5 text-center">
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">

                                    <img className="" src="../assets/img/1.webp" alt="Choose Your Number"/>
                                    <h4 className="pt-2">Choose Your Number </h4>
                                    <p className="mb-2">Select a local, national or toll-free number from our vast inventory.
                                    </p>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    
                                    <img className="" src="../assets/img/2.webp" alt="Activate"/>
                                    <h4 className="pt-2">Activate</h4>
                                    <p className="mb-2">Let us handle the sign-up - it takes just 3 minutes.</p>

                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="" src="../assets/img/3.webp" alt="Receive Calls"/>
                                    <h4 className="pt-2">Receive Calls</h4>
                                    <p className="mb-2">Immediately start receiving calls and servicing your customers. </p>

                                </div>
                                
                            </div>
                            
                        </div>
                        

                    </div>
                    <div className="d-flex justify-content-center pt-md-10 " data-group="join" data-delay="900">
                    
                        <span><Link to={{ pathname: "https://panel.didbiz.com/auth/register/" }} target="_blank"  className="btn btn-lg btn-outline-primary rounded-pill mx-1">Sign Up Today</Link> </span>
                    </div>

                </div>

            </section>
            <section id="snippet-4" className="wrapper bg-light wrapper-border">
                <div className="container pt-5 pt-md-10 pb-13 pb-md-10">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                        <div className="col-lg-7 order-lg-2">
                            <div className="row gx-md-5 gy-5">
                                <div className="col-md-5 offset-md-1 align-self-end">
                                    <div className="card bg-pale-yellow">
                                        <div className="card-body">
                                            <img className="rounded-circle " src="../assets/img/CommPeak-Dialer.webp" alt="CommPeak Dialer"/>
                                            <h4>CommPeak Dialer</h4>
                                            <p className="mb-0">Boost your calling
                                                productivity and sales</p>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                                <div className="col-md-6 align-self-end">
                                    <div className="card bg-pale-red">
                                        <div className="card-body">
                                            <img className="rounded-circle " src="../assets/img/SMS-Messaging-API.webp" alt="SMS Messaging API"/>
                                            <h4>SMS Messaging API</h4>
                                            <p className="mb-0">Connect to customers with
                                                high-quality SMS routes. </p>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                                <div className="col-md-5">
                                    <div className="card bg-pale-leaf">
                                        <div className="card-body">
                                            <img className="rounded-circle " src="../assets/img/VoIP-Service.webp" alt="VoIP Service"/>
                                            <h4>VoIP Service</h4>
                                            <p className="mb-0">Manage your
                                                customer communications.</p>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                                <div className="col-md-6 align-self-start">
                                    <div className="card bg-pale-primary">
                                        <div className="card-body">
                                            <img className="rounded-circle " src="../assets/img/Cloud-PBX.webp" alt="Cloud PBX"/>
                                            <h4>Cloud PBX</h4>
                                            <p className="mb-0">Manage your customer
                                                communications. </p>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-lg-5">

                            <h3 className="display-4 mb-5">Easily Use Your DID Numbers With
                            </h3>
                            <p>DID numbers have been traditionally used by virtual phone systems to route calls from
                                different clients. </p>
                            <p>With the development of VoIP technology, the traditional DID system has been expanded and
                                transformed into an IP network. This network enables users to enjoy improved features and
                                lower cost, while still maintaining the same features that a traditional DID system
                                provides. </p>
                            <p>With this service, we can make and receive unlimited calls to any number in the world at very
                                low rates. </p>
                            <Link to="/contact-us" className="btn btn-navy rounded-pill mt-3">Contact Us Today!</Link> 
                        </div>
                        
                    </div>
                    
                </div>

            </section>
            <section className="wrapper bg-light">
                <div className="container justify-content-center mt-10 mb-md-12">
                    <h3 className="display-3 px-xl-10 text-center ">Why does your business need a DID Number?
                    </h3>
                    <div className="row gx-md-5 gy-5 pt-5 text-center">
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">

                                    <img className="" src="../assets/img/Easy-to-Manage.webp" alt="Easy to Manage"/>
                                    <h4 className="pt-2">Easy to Manage </h4>
                                    <p className="mb-2">Imagine a world where you can have
                                        one phone number for all your business
                                        needs. Now that's reality with our
                                        innovative IVR system and automated
                                        redirection technology!
                                    </p>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    
                                    <img className="" src="../assets/img/Better-Control.webp" alt="Better Control"/>
                                    <h4 className="pt-2">Better Control</h4>
                                    <p className="mb-2">With our advanced Call Forwarding
                                        features, you can route all your calls via
                                        a DID number and keep track of them in
                                        one place.
                                    </p>
                                    <br/>

                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="" src="../assets/img/Sound-Competent.webp" alt="Sound Competent"/>
                                    <h4 className="pt-2">Sound Competent</h4>
                                    <p className="mb-2">When you need to take action on your
                                        customer calls, this is the information
                                        that will help. The DID number can be
                                        used for resolving disputes and
                                        confusion in conversations easily!</p>

                                </div>
                                
                            </div>
                            
                        </div>
                        

                    </div>
                    <div className="row gx-md-5 gy-5 pt-5 text-center">
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">

                                    <img className="" src="../assets/img/Daily-Detailed-Analytics.webp" alt="Daily Detailed Analytics"/>
                                    <h4 className="pt-2">Daily Detailed Analytics</h4>
                                    <p className="mb-2">Your customers will love you for it!
                                        For example, if they call the company
                                        and get disconnected or put on hold
                                        because of a busy signal then there is
                                        no need to worry.
                                    </p>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    
                                    <img className="" src="../assets/img/One-Number-for-Business.webp" alt="One Number for Business"/>
                                    <h4 className="pt-2">One Number for Business</h4>
                                    <p className="mb-2">You can finally get all your team's
                                        numbers in one place. No more losing
                                        customer information and trying to
                                        juggle multiple phone lines!
                                    </p>
                                    <br/>

                                </div>
                                
                            </div>
                            
                        </div>
                        
                        <div className="col-md-6 col-xl-4">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="" src="../assets/img/Scale-as-You-Grow.webp" alt="Scale as You Grow"/>
                                    <h4 className="pt-2">Scale as You Grow</h4>
                                    <p className="mb-2">Scale as you go without having to
                                        worry about bandwidth limits or the
                                        number of channels. No limit on how
                                        many parallel calls there are either!
                                    </p>
                                    <br/>

                                </div>
                                
                            </div>
                            
                        </div>
                        

                    </div>
                    <div className="d-flex justify-content-center pt-md-10 " data-group="join" data-delay="900">
                        
                        <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Contact Us Today!</Link> </span>
                    </div>

                </div>

            </section>


            <section className="wrapper bg-light">
                <div className="container pb-3 pb-lg-3">

                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-10 ">
                        <div className="col-lg-6 order-lg-2">
                            <img src="../assets/img/Try-the-Top-International-DID-Number-Provider-Risk-Free.webp" className="img-fluid" alt="Try the Top International DID
                                Number Provider Risk-Free"/>
                        </div>
                        
                        <div className="col-lg-6">
                            <h3 className="display-4 mt-xxl-8 mb-3 text-justify">Try the Top International DID
                                Number Provider Risk-Free</h3>
                            <p className="lead fs-lg lh-sm mb-6">The DidBiz is one of the best DID number providers in the
                                world. They have been in business for more than 20 years
                                and have a huge customer base. Moreover, they provide
                                their customers with an easy, fast and reliable service.</p>
                            <p className="lead fs-lg lh-sm mb-6">No other international VoIP DID provider makes it so easy
                                and affordable to purchase DID numbers in over 170
                                countries. There are no setup fees or cancellation costs. Buy
                                a DID number and start managing calls in minutes!</p>
                            <div className="d-flex justify-content  " data-group="join" data-delay="900">
                                
                                <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Contact Us
                                        Today!</Link> </span>
                            </div>

                        </div>
                        
                    </div>
                </div>

            </section>
            
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400"
                        data-image-src="../assets/img/photos/bg2.jpg"
                        >
                        <div className="pt-4">
                            <h3 className="text-white text-center">Want to Test Our DID Numbers?</h3>
                            <p className=" text-white text-center">Book your Test DID Now and Try it for Free!!!</p>
                            <div className="d-flex justify-content-center pb-5  " data-group="join" data-delay="900">
                                
                                <span><Link to="/contact-us" className="btn btn-lg btn-outline-primary rounded-pill mx-1">Contact Us
                                        Today!</Link> </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        
        </>
    );
}

export default didNumber;