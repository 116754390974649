import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <>
         <footer className="bg-navy text-inverse">
            <div className="container pt-15 pt-md-1 pb-13 pb-md-15">
                {/* <div className="d-lg-flex flex-row align-items-lg-center">
                    <h3 className="display-4 mb-6 mb-lg-0 pe-lg-20 pe-xl-22 pe-xxl-25 text-white">Join our community by using our services and grow your business.</h3>
                    <a href="#" className="btn btn-primary rounded-pill mb-0 text-nowrap">Try It For Free</a>
                </div> */}
                <hr className="mt-11 mb-12" />
                <div className="row gy-6 gy-lg-0">
                    <div className="col-md-4 col-lg-3">
                        <div className="widget">
                        <h4 className="widget-title text-center text-white mb-3">DIDBIZ </h4>
                        <p className="mb-5 justify-content-center">Here at Didbiz, We are 100% client service oriented. We see the desires our clients have when it includes quality Didnumbers, messaging, Programmable Voice, and SIP Trunking</p>
                        {/* <div className="newsletter-wrapper">
                            <div id="mc_embed_signup2">
                                <form action="https://elemisfreebies.us20.list-manage.com/subscribe/post?u=aa4947f70a475ce162057838d&amp;id=b49ef47a9a" method="post" id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form" className="validate dark-fields" target="_blank" novalidate>
                                    <div id="mc_embed_signup_scroll2">
                                        <div className="mc-field-group input-group form-label-group">
                                            <input type="email" value="" name="EMAIL" className="required email form-control" placeholder="Email Address" id="mce-EMAIL2"/>
                                            <label for="mce-EMAIL2">Email Address</label>
                                            <input type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe2" className="btn btn-primary"/>
                                        </div>
                                        <div id="mce-responses2" className="clear">
                                            <div className="response" id="mce-error-response2" style={{display:'none'}}></div>
                                            <div className="response" id="mce-success-response2" style={{display:'none'}}></div>
                                        </div> 
                                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabindex="-1" value=""/></div>
                                        <div className="clear"></div>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                        </div>      
                        
                    </div>
                    <div className="col-md-4 col-lg-3">
                        <div className="widget">
                            <h4 className="widget-title text-white mb-3">Get in Touch</h4>
                            {/* <address className="pe-xl-15 pe-xxl-17">Moonshine St. 14/05 Light City, London, United Kingdom</address> */}
                            <a href="mailto:info@didbiz.com">info@didbiz.com</a><br /> 
                            <a href="tel:+18445339911">(844) 533-9911</a><br /> 
                            <a href="">Telegram</a><br /> 
                            <a href="">Skype</a><br /> 
                            
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                        <div className="widget">
                            <h4 className="widget-title text-white mb-3">Services</h4>
                            <ul className="list-unstyled mb-0">
                                <li><Link to="/did-numbers">DiD Numbers</Link></li>
                                <li><Link to="/messaging">Messaging</Link></li>
                                <li><Link to="/programming-voice">Programmable Voice</Link></li>
                                <li><Link to="/sip-trunking">SIP Trunking</Link></li>                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                        <div className="widget">
                            <img className="mb-4" src="src/img/logo-light.png" srcSet="src/img/logo-light@2x.png 2x" alt="" />
                            <p className="mb-4">© 2022 <Link to="/">DiDBiz.</Link> <br className="d-none d-lg-block" />All rights reserved.</p>
                            <nav className="nav social social-white">
                                <a href="#"><i className="uil uil-twitter"></i></a>
                                <a href="#"><i className="uil uil-facebook-f"></i></a>
                                <a href="#"><i className="uil uil-dribbble"></i></a>
                                <a href="#"><i className="uil uil-instagram"></i></a>
                                <a href="#"><i className="uil uil-youtube"></i></a>
                            </nav>
                        </div>
                        {/* <div className="widget">
                            <h4 className="widget-title text-white mb-3">Our Newsletter</h4>
                            <p className="mb-5">Subscribe to our newsletter to get our news & deals delivered to you.</p>
                            <div className="newsletter-wrapper">
                                <div id="mc_embed_signup2">
                                    <form action="https://elemisfreebies.us20.list-manage.com/subscribe/post?u=aa4947f70a475ce162057838d&amp;id=b49ef47a9a" method="post" id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form" className="validate dark-fields" target="_blank" novalidate>
                                        <div id="mc_embed_signup_scroll2">
                                            <div className="mc-field-group input-group form-label-group">
                                                <input type="email" value="" name="EMAIL" className="required email form-control" placeholder="Email Address" id="mce-EMAIL2"/>
                                                <label for="mce-EMAIL2">Email Address</label>
                                                <input type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe2" className="btn btn-primary"/>
                                            </div>
                                            <div id="mce-responses2" className="clear">
                                                <div className="response" id="mce-error-response2" style={{display:'none'}}></div>
                                                <div className="response" id="mce-success-response2" style={{display:'none'}}></div>
                                            </div> 
                                            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabindex="-1" value=""/></div>
                                            <div className="clear"></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                          </div>                         */}
                    </div>
                </div>
            </div>
        </footer>   
        </>
    );
}

export default Footer;