import React,{useEffect,useState, useCallback, useRef} from 'react';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { css } from "@emotion/react";
import SyncLoader from "react-spinners/SyncLoader";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    text-align:center;
    padding:20px;
`;



function Post(props) {
    const [isSending, setIsSending] = useState(false);
    let [loading, setLoading] = useState(false);
    const isMounted = useRef(true)

    const [blogdata, setblogdata] = useState([])
    const [recent_posts, setrecent_posts] = useState([])
    const params = useParams();
    useEffect(() => {
        // console.log(params.slug);
        getData();
    }, [])

    const sendRequest = useCallback(async (slug) => {
        // don't send again while we are sending
        if (isSending) return
        // update state
        setIsSending(true)
        // send the actual request
        await getData(slug)
        // once the request is sent, update state again
        if (isMounted.current) // only update if we are still mounted
          setIsSending(false)
      }, [isSending]) // update the callback if the state changes
    

    const getData= async(slug)=>{
            // console.log(slug);
            setLoading(true);
            if(slug == undefined){
                slug = params.slug
            }
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            await fetch('https://webapi.didbiz.com/getblog/'+slug, {
                method:"POST",
                headers: myHeaders,                
            }).then(response => response.json()).then(res =>{
                if(res.status == 201){   
                    setblogdata(res.data)
                    setrecent_posts(res.recentpost)
                }
            }).catch(err => console.log(err));
            setLoading(false);
    }

    return (
       <>
       {!loading && blogdata && blogdata.length>0 ?
            <>
            <section  className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400"
                        data-image-src="../../assets/img/photos/bg1.jpg" style={{'background': '#373174'}}>
            <div className="container pt-5 pb-5 pt-md-10 pb-md-10 text-center">
                <div className="row">
                <div className="col-md-10 col-xl-8 mx-auto">
                    <div className="post-header">
                    {/* <div className="post-category text-line text-white">
                        <a href="#" className="text-reset" rel="category">Teamwork</a>
                    </div> */}
                    
                    <h1 className="display-1 mb-4 text-white">{blogdata[0]['page_title']}</h1>
                    <ul className="post-meta text-white">
                        <li className="post-date"><i className="uil uil-calendar-alt"></i><span>
                        <Moment format="YYYY/MM/DD HH:mm:ss">
                            {blogdata[0].date}
                        </Moment>    
                        </span></li>
                        {/* <li className="post-author"><i className="uil uil-user"></i><a href="#" className="text-reset"><span>By Sandbox</span></a></li>
                        <li className="post-comments"><i className="uil uil-comment"></i><a href="#" className="text-reset">3<span> Comments</span></a></li>
                        <li className="post-likes"><i className="uil uil-heart-alt"></i><a href="#" className="text-reset">3<span> Likes</span></a></li> */}
                    </ul>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
            
            </section>
            <section className="wrapper bg-light">
            <div className="container py-5 py-md-10">
                <div className="row gx-lg-8 gx-xl-12">
                <div className="col-lg-8">
                    <div className="blog single">
                    <div className="card">
                        <figure className="card-img-top"><img src={'https://www.rayvat.com/admin/assets/images/blog/'+blogdata[0].Image} alt={blogdata[0].Image.replace(/.jpg/g,"").replace(/.webp/g,"").replace(/-/g," ")} /></figure>
                        <div className="card-body">
                        <div className="classic-view">
                            <article className="post">
                            <div className="post-content mb-5">
                                <div dangerouslySetInnerHTML={{ __html:blogdata[0].content.replace("class=", 'className=') }} />
                            </div>
                            </article>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                <aside className="col-lg-4 sidebar mt-11 mt-lg-6">
                    <div className="widget">
                    <h4 className="widget-title mb-3">About Us</h4>
                    <p>Here at Didbiz, We are 100% client service oriented. We see the desires our clients have when it includes quality Didnumbers, messaging, Programmable Voice, and SIP Trunking.</p>
                    <h4 className="widget-title mb-3">Social Media</h4>
                    <nav className="nav social">
                        <Link to="#"><i className="uil uil-twitter"></i></Link>
                        <Link to="#"><i className="uil uil-facebook-f"></i></Link>
                        <Link to="#"><i className="uil uil-linkedin"></i></Link>
                        <Link to="#"><i className="uil uil-instagram"></i></Link>
                        {/* <Link to="#"><i className="uil uil-youtube"></i></Link> */}
                    </nav>
                    
                    <div className="clearfix"></div>
                    </div>

                    <div className="widget">
                    <h4 className="widget-title mb-3">Services</h4>
                    <ul className="unordered-list bullet-primary text-reset">
                        <li><Link to="/did-numbers">Phone Number (DID)</Link></li>
                        <li><Link to="/messaging">Messaging</Link></li>
                        <li><Link to="/programming-voice">Programmable Voice</Link></li>
                        <li><Link to="/sip-trunking">SIP trunking</Link></li>                        
                    </ul>
                    </div>
                    
                    <div className="widget">
                    <h4 className="widget-title mb-3">Popular Posts</h4>
                    <ul className="image-list">
                        {recent_posts && recent_posts.length>0 &&
                        recent_posts.map((item,i) =>
                        <li>
                        <figure className="rounded"><Link to={'/blog/'+item.slug} onClick={()=>sendRequest(item.slug)}><img src={'https://www.rayvat.com/admin/assets/images/blog/'+item.Image} alt={item.Image.replace(/.jpg/g,"").replace(/.webp/g,"").replace(/-/g," ")} /></Link></figure>
                        <div className="post-content">
                            <h6 className="mb-2"> <Link className="link-dark" to={'/blog/'+item.slug} onClick={()=>sendRequest(item.slug)}>{item.page_title}</Link> </h6>
                            <ul className="post-meta">
                            <li className="post-date"><i className="uil uil-calendar-alt"></i><span>
                            <Moment format="YYYY/MM/DD HH:mm:ss">
                                {item.date}
                            </Moment>        
                            </span></li>
                            {/* <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>3</a></li> */}
                            </ul>
                            
                        </div>
                        </li>
                        )
                        }
                        
                        {/* <li>
                        <figure className="rounded"> <a href="blog-post.html"><img src="assets/img/photos/a2.jpg" alt="" /></a></figure>
                        <div className="post-content">
                            <h6 className="mb-2"> <a className="link-dark" href="blog-post.html">Ornare Nullam Risus</a> </h6>
                            <ul className="post-meta">
                            <li className="post-date"><i className="uil uil-calendar-alt"></i><span>16 Feb 2021</span></li>
                            <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>6</a></li>
                            </ul>
                            
                        </div>
                        </li>
                        <li>
                        <figure className="rounded"><a href="blog-post.html"><img src="assets/img/photos/a3.jpg" alt="" /></a></figure>
                        <div className="post-content">
                            <h6 className="mb-2"> <a className="link-dark" href="blog-post.html">Euismod Nullam Fusce</a> </h6>
                            <ul className="post-meta">
                            <li className="post-date"><i className="uil uil-calendar-alt"></i><span>8 Jan 2021</span></li>
                            <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>5</a></li>
                            </ul>
                            
                        </div>
                        </li> */}
                    </ul>
                    
                    </div>
                    
                    {/* <div className="widget">
                    <h4 className="widget-title mb-3">Categories</h4>
                    <ul className="unordered-list bullet-primary text-reset">
                        <li><a href="#">Teamwork (21)</a></li>
                        <li><a href="#">Ideas (19)</a></li>
                        <li><a href="#">Workspace (16)</a></li>
                        <li><a href="#">Coding (7)</a></li>
                        <li><a href="#">Meeting (12)</a></li>
                        <li><a href="#">Business Tips (14)</a></li>
                    </ul>
                    </div>
                    
                    <div className="widget">
                    <h4 className="widget-title mb-3">Tags</h4>
                    <ul className="list-unstyled tag-list">
                        <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill">Still Life</a></li>
                        <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill">Urban</a></li>
                        <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill">Nature</a></li>
                        <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill">Landscape</a></li>
                        <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill">Macro</a></li>
                        <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill">Fun</a></li>
                        <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill">Workshop</a></li>
                        <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill">Photography</a></li>
                    </ul>
                    </div>
                    
                    <div className="widget">
                    <h4 className="widget-title mb-3">Archive</h4>
                    <ul className="unordered-list bullet-primary text-reset">
                        <li><a href="#">February 2019</a></li>
                        <li><a href="#">January 2019</a></li>
                        <li><a href="#">December 2018</a></li>
                        <li><a href="#">November 2018</a></li>
                        <li><a href="#">October 2018</a></li>
                    </ul>
                    </div> */}
                    
                </aside>
                
                </div>
                
            </div>
            
            </section>
            </>
            :<SyncLoader css={override} size={10} />
        }
            
       </>
    );
}

export default Post;